import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ViewMode } from '../models/enums/view-mode/view-mode';
import { WorkMode } from '../models/enums/work-mode/work.mode';
import { ObjUtils } from './obj.utils';
import { ViewModeUtils } from './view.mode.utils';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {

  //public static readonyl INCIDENT_DETAILS_URL = ''

  public previousUrl: string;
  public currentUrl: string;

  private urlChangeSub: Subscription;

  constructor(private readonly router: Router,
              private readonly location: Location,
              private readonly logger: NGXLogger) {
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public navigateBack() {
    this.location.back();
  }

  public navigateToIncidentList(viewMode?: ViewMode): void {
    this.navigateWithQueryParamsAndFragment(['/incident', 'list'], viewMode, null, null, null);
  }

  public navigateToIncidentOverview(incidentUuid: string, viewMode?: ViewMode, fragment?: string): void {
    this.navigateWithQueryParamsAndFragment(['/incident', 'detail', incidentUuid], viewMode, null, false, fragment);
  }
  public getIncidentOverviewURL(incidentUuid: string): string {
    return this.router.serializeUrl(this.router.createUrlTree(['/incident', 'detail', incidentUuid]));
  }
  public navigateToOrdersOverview(incidentUuid: string): void {
    this.navigateWithQueryParamsAndFragment(['/incident', 'detail', incidentUuid], undefined, undefined, undefined, 'orders');
  }

  public navigateToOrderCreation(incidentUuid: string, lightingNo: string, controlCabinetNo: string, wishResolutionDate: string) {
    const params = {
      lightingNo,
      controlCabinetNo,
      wishResolutionDate
    };

    this.router.navigate([`${incidentUuid}/orders/create`, this.cleanParams(params)]);
  }

  public navigateToOrderEditing(incidentUuid: string, lightingNo: string, controlCabinetNo: string, wishResolutionDate: string) {
    const params = {
      lightingNo,
      controlCabinetNo,
      wishResolutionDate
    };

    this.router.navigate([`${incidentUuid}/orders/edit`, this.cleanParams(params)]);
  }

  public navigateToIncidentCreation(incidentUuid?: string, viewMode?: ViewMode, unlink?: boolean): void {
    const incUuid: string = ObjUtils.isNullOrUndefined(incidentUuid)? '' : incidentUuid;
    this.navigateWithQueryParamsAndFragment(['/incident', 'create', 'new', incUuid], viewMode, null, unlink, null);
  }

  public navigateToOrderFeedbackCreation(incidentUuid: string, orderId: string, lightingNo: string, controlCabinetNo: string, canBeClosed: boolean): void {
    const params = {
      lightingNo,
      controlCabinetNo,
      canBeClosed
    };
    this.router.navigate(['/incident', incidentUuid, 'orders', orderId, 'order-feedbacks', 'create', this.cleanParams(params)]);
  }

  public navigateWithQueryParamsAndFragment(pathSegments: string[], viewMode?: ViewMode, workMode?: WorkMode, unlink?: boolean, fragment?: string): void {
    const filteredPathSegements: string[] = ObjUtils.isEmptyArray(pathSegments)? [] : pathSegments.filter(ps => !ObjUtils.isNullOrUndefinedOrEmpty(ps));
    this.router.navigate(filteredPathSegements, this.createNavigationExtras(viewMode, workMode, unlink, fragment));
  }

  public createNavigationExtras(viewMode?: ViewMode, workMode?: WorkMode, unlink?: boolean, fragment?: string): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      fragment: !ObjUtils.isNullOrUndefinedOrEmpty(fragment)? fragment : null,
      queryParams: this.createQueryParams(viewMode, workMode, unlink),
    }
    return navigationExtras;
  }

  public createQueryParams(viewMode?: ViewMode, workMode?: WorkMode, unlink?: boolean): any {
    const queryParams = {
      viewMode: !!ViewModeUtils.isViewModeOwn(viewMode)? viewMode.toLowerCase() : null,
      workMode: !ObjUtils.isNullOrUndefined(workMode)? workMode.toLowerCase() : null,
      unlink: !!unlink ? true : null,
    };
    return queryParams;
  }

  private subscribe(): void {
    this.urlChangeSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.logger.trace('previous url=', this.previousUrl);
        this.logger.trace('current url=', this.currentUrl);
      });
  }

  /**
   * Deletes properties that have undefined or null as a value so they don't get passed as a query param having the string value 'null'
   */
  private cleanParams(params: any) {
    Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null) && delete params[key]);
    return params;
  }

  private unsubscribe(): void {
    this.urlChangeSub.unsubscribe();
  }
}
