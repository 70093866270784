import { Pipe, PipeTransform } from '@angular/core';
import { UserMappingRole } from '../../api/generated/v2/incident';

@Pipe({
  name: 'userMappingRoleLabel',
})
export class UserMappingRoleLabelPipe implements PipeTransform {

  transform(value: UserMappingRole): string {
    switch (value) {
      case UserMappingRole.ConstructionCoordination:
        return 'Baukoordination';
      case UserMappingRole.OperationsService:
        return 'Betriebsservice';
      case UserMappingRole.ExternalServiceProvider:
        return 'Externer Dienstleister';
      case UserMappingRole.TechnicalSales:
        return 'Fachvertrieb';
      case UserMappingRole.TechnicalInsideSales:
        return 'Fachvertrieb Innendienst';
      case UserMappingRole.InternalInstaller:
        return 'Interner Monteur';
      case UserMappingRole.Foreman:
        return 'Meister';
      case UserMappingRole.ProjectPlanning:
        return 'Projektierung';
    }
  }

}
