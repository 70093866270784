import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { CreateUserMappingModalComponent } from '../create-user-mapping-modal/create-user-mapping-modal.component';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { UserMappingTO, UserSettingsTO } from '../../../api/generated/v2/incident';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { debounceTime, share, takeUntil } from 'rxjs/operators';
import { UserMappingDataService } from '../../services/user-mapping-data.service';
import { Spinner } from '../spinner-modal/spinner';
import { ActionsMenuOptionType } from '../../models/enums/company/actions-menu-option-type';
import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';
import { UserMappingDataModalImpl } from './user-mapping-data-modal.impl';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjUtils } from '../../services/obj.utils';
import { User } from '../../models/user';
import { loggedInUserSelector } from '../../../../auth/state/auth.reducers';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app-state';
import { UserRoleUtils } from '../../services/user.role.utils';
import { UserInfoService } from '../../services/user.info.service';
import { SystemUserSettings } from '../../models/systemUserSettings';
import { HttpResponse } from '@angular/common/http';
import { loadUserSettingsRequest } from '../../../state/shared.actions';

@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss'],
})
export class UserMappingComponent implements OnInit {

  private paginator: MatPaginator;

  @ViewChild('paginator', { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  private loggedInUser: User;
  private userSettings: SystemUserSettings;
  private userEmailsFavorites: string[] = [];

  dataSource: MatTableDataSource<UserMappingTO> =
    new MatTableDataSource();

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private store: Store<AppState>,
              private router: Router,
              private userInfoService: UserInfoService,
              private userMappingDataService: UserMappingDataService,
              private navigationService: NavigationService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'star_empty',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/star_empty.svg',
      ),
    );

    iconRegistry.addSvgIcon(
      'star_filled',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/star_filled.svg',
      ),
    );
  }

  filter$: BehaviorSubject<any> = new BehaviorSubject(null);
  pagination$: BehaviorSubject<any> = new BehaviorSubject(null);
  userMappings$: BehaviorSubject<UserMappingTO[]> = new BehaviorSubject([]);

  destroyed$: Subject<any> = new Subject();

  public displayedColumns: string[] = [
    'favorite',
    'userName',
    'type',
    'company',
    'role',
    'email',
    'actions',
  ];

  pageIndex = 0;
  pageSize = 10;
  totalRows = 0;

  ngOnInit(): void {
    this.userMappings$.subscribe((userMappings: UserMappingTO[]) => {
      this.dataSource.data = userMappings;
    });

    this.route.queryParamMap.subscribe(
      (params) => {
        this.pageSize = params.get('pageSize') ? Number(params.get('pageSize')) : 10;
        this.pageIndex = params.get('pageIndex') ? Number(params.get('pageIndex')) : 0;
        this.pagination$.next({ pageSize: this.pageSize, pageIndex: this.pageIndex });
      });

    this.filter$.subscribe(_ => {
      // reset pageIndex on filter change
      this.router.navigate([], {
        queryParams: {
          pageIndex: 0,
          pageSize: this.pageSize,
        },
        queryParamsHandling: 'merge',
      });
    });

    merge(this.filter$, this.pagination$)
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(250),
      )
      .subscribe(async () => {

        const chunk = await this.userMappingDataService
          .getAllUserMappings(this.pageIndex, this.pageSize)
          .toPromise() as any;
        this.totalRows = chunk?.totalCount || 0;

        setTimeout(() => {
          this.paginator.pageIndex = chunk?.totalCount ? this.pageIndex : 0;
          this.paginator.length = chunk?.totalCount || 0;
        });

        this.userMappings$.next(chunk?.data || []);
      });

    this.store.select(loggedInUserSelector).subscribe((user: User) => {
      this.loggedInUser = user;
      if (!this.isValidUser()) {
        return;
      }
      this.loadUserSettings(UserRoleUtils.getUserId(this.loggedInUser), this.loggedInUser.internal);
    });
  }

  public openAddUserDialog(): void {

    const data: UserMappingDataModalImpl = {
      operation: ActionsModalOperationType.CREATE,
    };

    const dialogRef = this.dialog.open(CreateUserMappingModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((res: DialogResult) => {
      if (DialogResult.SUCCESS === res) {
        this.loadUsers();
      }
    });
  }

  public onBackBtnClick(): void {
    this.navigationService.navigateToIncidentList();
  }

  public handlePageChange(event: PageEvent) {
    const spinner = new Spinner();
    spinner.show(this.dialog, { message: 'Laden...' });

    this.router.navigate([], {
      queryParams: {
        pageIndex: event.pageIndex,
        pageSize: event.pageSize,
      },
      queryParamsHandling: 'merge',
    });

    this.userMappings$.subscribe((rules) => {
      this.dataSource.data = rules;
      spinner.close();
    });
  }

  private loadUsers(): void {
    this.userMappingDataService.getAllUserMappings(this.pageIndex, this.pageSize).subscribe(result => {
      this.dataSource.data = result.data || [];
    });
  }

  public ruleOptions(): ActionsMenuOptionType[] {
    return [
      ActionsMenuOptionType.EDIT,
      ActionsMenuOptionType.DELETE,
    ];
  }

  public handleOptionClicked(
    userMappingTo: UserMappingTO,
    option: ActionsMenuOptionType,
  ) {
    switch (option) {
      case ActionsMenuOptionType.EDIT: {
        this.handleOptionEdit(userMappingTo);
        break;
      }
      case ActionsMenuOptionType.DELETE: {
        this.handleOptionDelete(userMappingTo.uuid);
        break;
      }
    }
  }

  private handleOptionDelete(userMappingUuid: string): void {
    this.userMappingDataService.deleteUserMapping(userMappingUuid).subscribe(() => {
      this.loadUsers();
    });
  }

  private handleOptionEdit(userMappingTo: UserMappingTO): void {
    const data: UserMappingDataModalImpl = {
      operation: ActionsModalOperationType.UPDATE,
      uuid: userMappingTo.uuid,
      userName: userMappingTo.userName,
      company: userMappingTo.company,
      role: userMappingTo.role,
      type: userMappingTo.type,
      email: userMappingTo.email,
    };

    const dialogRef = this.dialog.open(CreateUserMappingModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((res: DialogResult) => {
      if (DialogResult.SUCCESS === res) {
        this.loadUsers();
      }
    });
  }

  handleOnFavoriteClick(userMappingTo: UserMappingTO): void {
    const favoriteUserMail = userMappingTo.email;
    if (this.isEmailFavorite(favoriteUserMail)) {
      this.userEmailsFavorites = this.userEmailsFavorites.filter(email => email !== favoriteUserMail);
    } else {
      this.userEmailsFavorites.push(favoriteUserMail);
    }
    this.updateUserSettings();
  }

  private isValidUser(): boolean {
    return !ObjUtils.isNullOrUndefined(this.loggedInUser)
      && !ObjUtils.isNullOrUndefinedOrEmpty(this.loggedInUser.email)
      && !!UserRoleUtils.hasRoleOperator(this.loggedInUser);
  }

  private loadUserSettings(userId: string, internal?: boolean): void {
    const userSettingsObs: Observable<any> = this.userInfoService.getSystemUserSettings(userId, !!internal).pipe(share());
    new Spinner().spin(userSettingsObs, this.dialog, { message: 'Lädt Nutzereinstellungen...' });
    userSettingsObs.subscribe((userSettings: UserSettingsTO) => {
        this.userSettings = userSettings;
        this.userEmailsFavorites =
          ObjUtils.isNullOrUndefined(userSettings) || ObjUtils.isEmptyArray(userSettings.emailFavorites) ?
            [] : userSettings.emailFavorites;
      }, (error: any) => {
        this.userEmailsFavorites = [];
      },
    );
  }

  isEmailFavorite(email: string): boolean {
    return this.userEmailsFavorites.includes(email);
  }

  updateUserSettings(): void {
    this.userSettings.emailFavorites = this.userEmailsFavorites;
    const obs: Observable<any> = this.userInfoService.saveUserSettings(this.loggedInUser.email, this.userSettings).pipe(share());
    new Spinner().spin(obs, this.dialog, { message: 'Nutzereinstellungen speichern...' });
    obs.subscribe((resp: HttpResponse<any>) => {
      this.store.dispatch(loadUserSettingsRequest({
        userId: UserRoleUtils.getUserId(this.loggedInUser),
        internal: !!this.loggedInUser.internal,
      }));
    }, (error: any) => {
      console.log('error saving user settings=', error);
    });
  }
}
