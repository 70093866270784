import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app-state';
import { ValidationService } from '../../services/validation.service';
import { CustomizedErrorStateMatcher } from '../../../state/custom.error-matcher';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Spinner } from '../spinner-modal/spinner';
import {
  DiConUserTO,
  IncidentTOV2,
  MessageTOV2,
  UserMappingType,
} from '../../../api/generated/v2/incident';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { MessagesDataService } from '../../services/messages-data.service';

@Component({
  selector: 'app-create-message-dialog',
  templateUrl: './create-message-dialog.component.html',
  styleUrls: ['./create-message-dialog.component.scss']
})
export class CreateMessageDialogComponent {

  @ViewChild('usersInput')
  public emailsInputRef: ElementRef<HTMLInputElement>;

  messageCreationFormGroup = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    wishResolutionDate: new UntypedFormControl(''),
    message: new UntypedFormControl('', [Validators.maxLength(4000)]),
    assignedTo: new UntypedFormControl([], [Validators.required]),
    isEmailSent: new UntypedFormControl(''),
    isDone: new UntypedFormControl('')
  });

  maximumMessageContentLength = 4000;
  selectedUsers: DiConUserTO[] = [];
  autocompleteUser$: Observable<DiConUserTO[]>;
  matcher = new CustomizedErrorStateMatcher();
  nowDate = new Date();
  separatorKeysCodes: number[] = [SPACE, ENTER];

  constructor(
    public dialogRef: MatDialogRef<CreateMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      incident: IncidentTOV2;
      allDiconUsers: DiConUserTO[]
    },
    private dialog: MatDialog,
    private store: Store<AppState>,
    public validationService: ValidationService,
    private messagesService: MessagesDataService
  ) {
    this.autocompleteUser$ = this.userName.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUsers(value)),
      );
  }

  get wishResolutionDate(): AbstractControl {
    return this.messageCreationFormGroup.get('wishResolutionDate');
  }

  get currentMessageContentLength(): number {
    return this.message.value.length;
  }

  get message(): AbstractControl {
    return this.messageCreationFormGroup.get('message');
  }

  get userName(): AbstractControl {
    return this.messageCreationFormGroup.get('userName');
  }

  onClsBtnClick(): void {
    this.dialogRef.close();
  }

  onConfirm(isEmailSent: boolean): void {
    if (this.messageCreationFormGroup.invalid) { return; }

    this.messageCreationFormGroup.patchValue({ isEmailSent });
    this.createMessage();
  }

  onUserRemove(index: number): void {
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
      this.updateAssignedTo();
    }
  }

  private createMessage() {
    if (!this.data.incident.uuid) { return; }

    const spinner = new Spinner();
    spinner.show(this.dialog, { message: 'Erstelle Nachricht...' });

    const formData = this.messageCreationForm;

    this.messagesService
      .createMessage(this.data.incident.uuid, formData)
      .subscribe(
        (res) => {
          this.dialogRef.close(DialogResult.SUCCESS);
        },
        (err: HttpErrorResponse) => {
          this.dialog.open(ErrorModalComponent, {
            disableClose: false,
            data: { message: 'Fehler beim Erstellen der Nachricht' }
          });
        },
        () => spinner.close()
      );
  }

  private get messageCreationForm(): MessageTOV2 {
    const date = new Date(this.wishResolutionDate.value);

    return {
      wishResolutionDateUtc: !isNaN(date.getTime())
        ? new Date(
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
        ).toISOString()
        : '',
      assignedTo: this.messageCreationFormGroup.get('assignedTo')?.value,
      message: this.message.value,
      isDone: false,
      isEmailSent: this.messageCreationFormGroup.get('isEmailSent')?.value
    };
  }

  isUserInternal(user: any) {
    return user.type === UserMappingType.InternalUser;
  }

  filterUsers(userName: any): DiConUserTO[] {
    const searchTerm = typeof userName === 'string' ? userName.toLowerCase() : '';
    return this.data.allDiconUsers.filter(
      user =>
        `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm) &&
        !this.selectedUsers.some(selected => selected.mail === user.mail)
    );
  }

  onUserSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedUser: DiConUserTO = event.option.value;

    if (!this.selectedUsers.some(u => u.mail === selectedUser.mail)) {
      this.selectedUsers.push(selectedUser);
      this.updateAssignedTo();
    }

    this.userName.setValue('');
    this.emailsInputRef.nativeElement.value = '';
  }

  private updateAssignedTo() {
    this.messageCreationFormGroup.get('assignedTo').setValue(this.selectedUsers.map(u => u.mail));
  }
}
