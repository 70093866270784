<div class='container'>
  <div class='page-header'>
    <h1 id='userDataH1'>Benutzerzuweisung</h1>
  </div>
  <div>
    <button mat-raised-button color='accent'
            (click)='openAddUserDialog()'>
      <mat-icon>add</mat-icon>
      Neuer Eutrag
    </button>
  </div>
  <div class='user-mapping-table'>
    <table mat-table [dataSource]='dataSource'>
      <ng-container matColumnDef='favorite'>
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef='let element'>
          <button id="favorite"
                  mat-icon-button
                  (click)="handleOnFavoriteClick(element)">
            <mat-icon id="starFilled" svgIcon="star_filled" *ngIf="isEmailFavorite(element.email)"></mat-icon>
            <mat-icon id="starEmpty" svgIcon="star_empty" *ngIf="!isEmailFavorite(element.email)"></mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef='userName'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Vor-Nachname</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span>
            {{ element.userName }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='type'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Type</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span>
            {{ element.type | userMappingTypeLabel }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='company'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Firma</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span>
            {{ element.company?.companyName }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='role'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Rolle</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span>
            {{ element.role | userMappingRoleLabel }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='email'>
        <th mat-header-cell *matHeaderCellDef>
          <span>E-Mail Adresse</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span>
            {{ element.email }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='actions'>
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef='let element'>
          <company-options-menu [options]='ruleOptions()'
                                (optionClicked)='handleOptionClicked(element, $event)'></company-options-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
      <tr mat-row *matRowDef='let row; columns: displayedColumns' [class.inactive-rule]='!row.active'></tr>
    </table>
    <mat-paginator
      #paginator
      [length]='totalRows'
      [pageSize]='pageSize'
      [pageSizeOptions]='[5, 10, 15]'
      [showFirstLastButtons]='true'
      [hidePageSize]='false'
      [pageIndex]='pageIndex'
      (page)='handlePageChange($event)'
    ></mat-paginator>
  </div>

  <div class='row'>
    <a id='btnback'
       mat-raised-button
       class='nd-btn nd-btn-outline btn-fnt btn-spc-leg'
       (click)='onBackBtnClick()'
       i18n='@@button.back'>Zurück</a>
  </div>
</div>
