/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMappingTO } from './userMappingTO';


/**
 * List of search results
 */
export interface UserMappingResults { 
    /**
     * Total number of searched user mappings
     */
    totalCount?: number;
    /**
     * List of user mappings
     */
    data?: Array<UserMappingTO>;
}

