import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './common/components/not-found/not-found.component';
import { ReleaseNotesComponent } from './common/components/release-notes/release-notes.component';
import { UserDataComponent } from './common/components/user-data/user-data.component';
import { CompaniesComponent } from './common/components/companies/companies.component';
import { UserMappingComponent } from './common/components/user-mapping/user-mapping.component';

const routes: Routes = [
  {
    path: 'releasenotes',
    component: ReleaseNotesComponent,
  },
  {
    path: 'settings',
    component: UserDataComponent,
  },
  {
    path: 'companies',
    component: CompaniesComponent,
  },
  {
    path: 'users',
    component: UserMappingComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class SharedRoutingModule { }
