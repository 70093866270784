import { Pipe, PipeTransform } from '@angular/core';
import { ActionsMenuOptionType } from '../models/enums/company/actions-menu-option-type';

@Pipe({
  name: 'optionLabel',
})
export class CompanyMenuOptionsLabelPipe implements PipeTransform {

  transform(value: ActionsMenuOptionType): string {
    switch (value) {
      case ActionsMenuOptionType.EDIT:
        return 'Bearbeiten';
      case ActionsMenuOptionType.DELETE:
        return 'Löschen';
      default:
        return '-';
    }
  }

}
