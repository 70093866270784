import { Injectable } from '@angular/core';
import { CompanyService, UserMappingService } from '../../api/generated/v2/incident';
import { EnvironmentService } from './env.service';
import { UserService } from '../../api/technical-data';

@Injectable({
  providedIn: 'root',
})
export class UserMappingDataService {

  constructor(private userMappingService: UserMappingService) {
    this.userMappingService.configuration.basePath = EnvironmentService.getEnvironment().incidentService;
  }

  public getAllUserMappings(pageIndex, pageSize) {
    return this.userMappingService.getAllUserMappings(pageIndex, pageSize);
  }

  public deleteUserMapping(companyId: string) {
    return this.userMappingService.deleteUserMapping(companyId, 'response');
  }

  public createUserMapping(userMapping) {
    return this.userMappingService.createUserMapping(userMapping,  'response');
  }

  public updateUserMapping(userMappingUuid, userMapping) {
    return this.userMappingService.updateUserMapping(userMappingUuid, userMapping, 'response');
  }
}
