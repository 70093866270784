import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyDataService } from '../../services/company-data.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { CompanyTO, UserMappingTO } from '../../../api/generated/v2/incident';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-delete-company-modal',
  templateUrl: './delete-company-modal.component.html',
  styleUrls: ['./delete-company-modal.component.scss']
})
export class DeleteCompanyModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteCompanyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected sanitizer: DomSanitizer,
    private readonly companyDataService: CompanyDataService,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('qm', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-mark.svg'));
  }

  company$: BehaviorSubject<CompanyTO> = new BehaviorSubject(undefined);
  companyData: CompanyTO;

  ngOnInit(): void {

    this.company$.subscribe((company: CompanyTO) => {
      this.companyData = company;
    });

    this.companyDataService.getCompany(this.data.companyUuid).subscribe((company: CompanyTO) => {
      this.company$.next(company || undefined);
    });
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public onRevokeBtnClick(): void {
    this.close(DialogResult.NO);
  }

  public onConfirmBtnClick(): void {
    this.deleteCompany();
  }

  private close(res: DialogResult): void {
    this.dialogRef.close(res);
  }

  private deleteCompany(): void {
    this.companyDataService.deleteCompany(this.data.companyUuid).subscribe(() => {
      this.dialogRef.close(DialogResult.SUCCESS);
    });
  }

}
