import { Injectable } from '@angular/core';
import { CompanyService } from '../../api/generated/v2/incident';
import { EnvironmentService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {

  constructor(private companyService: CompanyService) {
    this.companyService.configuration.basePath = EnvironmentService.getEnvironment().incidentService;
  }

  public getCompany(companyUuid) {
    return this.companyService.getCompanyByUuid(companyUuid);
  }

  public getAllCompanies(pageIndex, pageSize) {
    return this.companyService.getAllCompanies(pageIndex, pageSize);
  }

  public deleteCompany(companyId: string) {
    return this.companyService.deleteCompany(companyId);
  }

  public createCompany(company) {
    return this.companyService.createCompany(company,  'response');
  }

  public updateCompany(companyUuid, company) {
    return this.companyService.updateCompany(companyUuid, company, 'response');
  }
}
