import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { CommuneTO, CompanyTO } from '../../../api/generated/v2/incident';
import { LuxDataTechnicalService } from '../../services/ld.technical.service';
import { CompanyDataModalImpl } from '../companies/company-data-modal.impl';
import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';
import { CompanyDataService } from '../../services/company-data.service';

@Component({
  selector: 'app-create-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss'],
})
export class CompanyModalComponent implements OnInit {

  public createCompanyForm: UntypedFormGroup = new UntypedFormGroup({
    companyName: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    city: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    zipCode: new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]),
    address: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  autocompletedCityOptions$: Observable<any[]> = this.createCompanyForm
    .get('city').valueChanges
    .pipe(
      debounceTime(300),
      mergeMap((city: string) => this.communeSearch({ city })),
    );

  constructor(
    public dialogRef: MatDialogRef<CompanyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompanyDataModalImpl,
    protected sanitizer: DomSanitizer,
    private readonly ldtSvc: LuxDataTechnicalService,
    private readonly companyDataService: CompanyDataService,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('qm', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-mark.svg'));
  }

  ngOnInit(): void {
    if (this.data.operation === ActionsModalOperationType.UPDATE) {
      this.createCompanyForm.patchValue({
        companyName: this.data.companyName,
        city: this.data.city,
        zipCode: this.data.zipCode,
        address: this.data.address,
      });
    }
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public onRevokeBtnClick(): void {
    this.close(DialogResult.NO);
  }

  public onConfirmBtnClick(): void {
    if (this.data.operation === ActionsModalOperationType.CREATE) {
      this.createCompany();
    } else if (this.data.operation === ActionsModalOperationType.UPDATE) {
      this.updateCompany();
    }
  }

  private updateCompany() {
    const updateCompany: CompanyTO = {
      companyName: this.createCompanyForm.get('companyName').value,
      city: this.createCompanyForm.get('city').value,
      zipCode: this.createCompanyForm.get('zipCode').value,
      address: this.createCompanyForm.get('address').value,
    };

    this.companyDataService.updateCompany(this.data.uuid, updateCompany).subscribe((response: any) => {
      const isSuccess: boolean = 200 === response.status;
      this.close(isSuccess ? DialogResult.SUCCESS : DialogResult.FAIL);
    }, (error: any) => {
      this.close(DialogResult.FAIL);
    });
  }

  private createCompany(): void {

    const data: CompanyTO = {
      companyName: this.createCompanyForm.get('companyName').value,
      city: this.createCompanyForm.get('city').value,
      zipCode: this.createCompanyForm.get('zipCode').value,
      address: this.createCompanyForm.get('address').value,
    };

    this.companyDataService.createCompany(data).subscribe((response: any) => {
      const isSuccess: boolean = 200 === response.status;
      this.close(isSuccess ? DialogResult.SUCCESS : DialogResult.FAIL);
    }, (error: any) => {
      this.close(DialogResult.FAIL);
    });
  }

  private close(res: DialogResult): void {
    this.dialogRef.close(res);
  }

  communeSearch(params: any): Observable<CommuneTO[]> {
    return this.ldtSvc.getCommune(params).pipe(map(communes => {
      communes.forEach(c => c.ags = this.sanitizeCustomerScope(c.ags));
      return communes;
    }));
  }

  private sanitizeCustomerScope(customerScope) {
    if (!customerScope) {
      return customerScope;
    }
    if (!customerScope.match(/^(AGS|ZV)_/)) {
      customerScope = 'AGS_' + customerScope;
    }        // if no AGS or ZV prefix found, assume its AGS_
    return customerScope;
  }

  public onCommuneSelect(commune: CommuneTO | null) {
    if (commune == null) {
      this.createCompanyForm.get('city').setErrors({ incorrect: true });
    } else {
      this.createCompanyForm.get('city').patchValue(commune?.town);
      this.createCompanyForm.get('zipCode').patchValue(commune?.zipCode);
    }
  }
}
