/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Message object
 */
export interface MessageTOV2 { 
    messageUuid?: string;
    /**
     * Assigned persons
     */
    assignedTo: Array<string>;
    /**
     * Message to another person
     */
    message: string;
    wishResolutionDateUtc: string;
    /**
     * Information if the email was sent
     */
    isEmailSent: boolean;
    /**
     * Flag that contains information if the message was taken care of
     */
    isDone: boolean;
    createdAt?: string;
}

