<div class="cls-div">
  <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
    <mat-icon style="margin: 0px;">close</mat-icon>
  </button>
</div>
<h1 id="title" mat-dialog-title><mat-icon id="icon" svgIcon="place" style="height: 50px; width: 50px;"></mat-icon> Kartensuche</h1>

<div class="wrap">
  <div class="form-wrap col div-stl" style="max-width: 235px; height: 470px; align-items: flex-start;" >
    <form id="form-report" [formGroup]="reportForm">
      <div class="row div-stl" style="width: 235px;" formGroupName="location">
        <mat-form-field>
          <mat-label>Straße</mat-label>
          <input id="input-location-street" matInput formControlName="street" maxlength="50">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hausnummer</mat-label>
          <input id="input-location-houseNo" matInput formControlName="houseNo" maxlength="50">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Postleitzahl</mat-label>
          <input id="input-location-zipCode" matInput formControlName="zipCode" maxlength="5">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ort<span *ngIf="reportForm.value.location.customerScope">({{reportForm.value.location.customerScope}})</span></mat-label>
          <input matInput
                 formControlName="city"
                 [matAutocomplete]="cityAutoOptions"
                 [required]="!reportForm.value.location.lightPointNo && !reportForm.value.location.controlCabinetNo && !reportForm.value.location.allNo"
          >
          <mat-error *ngIf="!reportForm.value.location.city?.valid">Ort nicht gefunden</mat-error>
          <mat-autocomplete #cityAutoOptions class="city-autocomplete">
            <mat-option *ngFor="let commune of autocompletedCityOptions$ | async" [value]="commune.town" (onSelectionChange)="onCommuneSelect(commune)">
              {{ commune.zipCode }} {{ commune.town }} ({{ commune.ags }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field *ngIf="isLightningPointChecked && !isControlCabinetChecked">
          <mat-label>Leuchtstellennummer</mat-label>
          <input id="input-location-lightPointNo" matInput formControlName="lightPointNo" maxlength="17" pattern="^[A-Z]{5}-(SOLAR|PRIVA|\d{5})-\d{5}$" [required]="!reportForm.value.location.city">
        </mat-form-field>
        <mat-form-field *ngIf="isControlCabinetChecked && !isLightningPointChecked">
          <mat-label>Schaltstellennummer</mat-label>
          <input id="input-location-controlCabinetNo" matInput formControlName="controlCabinetNo" maxlength="12" pattern="^[A-Z]{5}-[A-Z]-\d{4}$">
        </mat-form-field>
        <mat-form-field *ngIf="isLightningPointChecked && isControlCabinetChecked">
          <mat-label>Leuchtstelle/Schaltstelle</mat-label>
          <input id="input-location-allNo" matInput formControlName="allNo" maxlength="17" pattern="^(?:[A-Z]{5}-(SOLAR|PRIVA|\d{5})-\d{5}|[A-Z]{5}-[A-Z]-\d{4})$">
        </mat-form-field>
        <div mat-dialog-actions style="width: 200px; display: flex; flex-direction: row;">
          <div *ngFor="let st of systemTypes" style="width: 100%;">
            <mat-checkbox [formControlName]="st.checkboxKey" (change)="onCheckboxChange()">
              {{ st.label }}
            </mat-checkbox>
          </div>
        </div>
        <div mat-dialog-actions style="width: 235px; display: flex; flex-direction: row;">
          <a id="searchBtn" mat-raised-button style="width: 220px; margin-left: 0px;"
            class="nd-btn nd-btn-outline btn-fnt btn-spc" [disabled]="!reportForm.get('location.city')?.valid" (click)="onSearchBtnClick()">Suchen</a>
          <a id="takeOverBtn" mat-raised-button style="width: 220px; margin-left: 0px; margin-top: 10px;"
            class="nd-btn nd-btn-main btn-fnt btn-spc"
            (click)="onTakeOverBtnClick()" [disabled]="!selectedDevice && !reportForm.valid">Übernehmen</a>
        </div>
      </div>
      <div>
        <div *ngIf='isControlCabinetChecked && isLightningPointChecked'>
          Leuchtstellen: {{ loading ? '-' : lpMarkers.length }}<br>
          Schaltstellen: {{ loading ? '-' : ccMarkers.length}}
        </div>
        <div *ngIf='!isControlCabinetChecked || !isLightningPointChecked'>
        {{ isControlCabinetChecked ? 'Schaltstellen' : 'Leuchstellen' }}: {{ loading ? '-' : markersAll.length }}
        </div>
        <div *ngIf="!loading && markersAll.length && markers.length == 0" class="zoom-info">
          Zu viele {{ isControlCabinetChecked && isLightningPointChecked ? 'Leucht-/Schaltstellen' : isControlCabinetChecked ? 'Schaltstellen' : 'Leuchstellen' }} gefunden oder nicht im Sichtbarkeitsbereich, bitte hinein zoomen oder Karte bewegen.
        </div>
      </div>
    </form>
  </div>

  <div class="map-wrap">
    <google-map [center]="center" [zoom]="zoom" width="100%" height="100%" (boundsChanged)="mapInteractionChanged()">
      <map-marker
        #mrk="mapMarker"
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="marker.options"
        (mapMouseover)="openInfo(mrk, marker.infoText1, marker.infoText2, marker.infoText3)"
        (mapClick)="selectObject(marker.deviceId)"
      >
      </map-marker>
      <map-info-window>
        {{infoContent1}}<br>
        {{infoContent2}}<br>
        <div style='color: red'>
          {{infoContent3}}
        </div>
      </map-info-window>
    </google-map>
  </div>

  <!-- <div class="row div-stl" style="width: 700px; margin: 0px; align-items: flex-start;">
      <div class="error-container" style="margin: 10px 0px 0px 0px;" *ngIf="!!loaded" >
        <span *ngIf="totalCount < 1" style="width: 698px; height: 56px;">
          Uns sind leider keine Daten im ausgewählten Kartenbereich bekannt.</span>
        <span *ngIf="totalCount > 0 && totalCount >= pageSize" style="width: 698px; height: 84px;">
          Hinweis: Es werden nur {{pageSize}} Elemente angezeigt. Für weitere Ergebnisse verfeinern Sie bitte die Suchkriterien.</span>
      </div>
  </div> -->
</div>
