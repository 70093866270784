import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';

export class CompanyDataModalImpl {
  operation: ActionsModalOperationType;
  uuid?: string;
  companyName?: string;
  city?: string;
  zipCode?: string;
  address?: string;
}
