import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { DocumentDeleteModalComponent } from './common/components/document-delete-modal/document-delete-modal.component';
import { DocumentUploadModalComponent } from './common/components/document-upload-modal/document-upload-modal.component';
import { DocumentsComponent } from './common/components/documents/documents.component';
import { ErrorModalComponent } from './common/components/error-modal/error-modal.component';
import { SpinnerModalComponent } from './common/components/spinner-modal/spinner-modal.component';
import { SharedRoutingModule } from './shared-routing.module';
import { DocumentFilenameChangeModalComponent } from './common/components/document-filename-change-modal/document-filename-change-modal.component';
import { GoogleMapModalComponent } from './common/components/google-map-modal/google-map-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { GoogleMapsModule } from '@angular/google-maps';
import { VersionInfoComponent } from './common/components/version-info/version-info.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ConfirmModalComponent } from './common/components/confirm-modal/confirm-modal.component';
import { ResultModalComponent } from './common/components/result-modal/result-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReleaseNotesComponent } from './common/components/release-notes/release-notes.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UserDataComponent } from './common/components/user-data/user-data.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotFoundComponent } from './common/components/not-found/not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { UserAvatarComponent } from './common/components/user-avatar/user-avatar.component';
import { MatMenuModule } from '@angular/material/menu';
import { OrderTypeLabelPipe } from './common/pipes/order-type-label.pipe';
import { PriorityLabelPipe } from './common/pipes/priority-label.pipe';
import { RbacShowDirective } from './common/directives/rbac-show.directive';
import { MiddleclickDirective } from './common/directives/middleclick.directive';
import { ServiceOutageDialogComponent } from './common/components/service-outage-dialog/service-outage.dialog.component';
import { MaintenanceDialogComponent } from './common/components/maintenance-dialog/maintenance-dialog.component';
import { DateTimeFormatPipe } from './common/pipes/date-time-format.pipe';
import { StatusCodePipe } from '../incidents/pipes/status-code.pipe';
import { SessionExpiredDialogComponent } from './common/components/session-expired-dialog/session-expired-dialog.component';
import { CompaniesComponent } from './common/components/companies/companies.component';
import { CompanyModalComponent } from './common/components/create-company-modal/company-modal.component';
import { CompanyOptionsMenuComponent } from './common/components/companies/company-options-menu/company-options-menu.component';
import { CompanyMenuOptionsLabelPipe } from './common/pipes/company-menu-options-label.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateMessageDialogComponent } from './common/components/create-message-dialog/create-message-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserMappingComponent } from './common/components/user-mapping/user-mapping.component';
import { CreateUserMappingModalComponent } from './common/components/create-user-mapping-modal/create-user-mapping-modal.component';
import { UserMappingRoleLabelPipe } from './common/pipes/user-mapping-role-label.pipe';
import { DeleteCompanyModalComponent } from './common/components/delete-company-modal/delete-company-modal.component';
import { UserMappingTypeLabelPipe } from './common/pipes/user-mapping-type-label.pipe';

@NgModule({
    declarations: [
        ErrorModalComponent,
        SpinnerModalComponent,
        DocumentDeleteModalComponent,
        DocumentUploadModalComponent,
        DocumentsComponent,
        DocumentFilenameChangeModalComponent,
        GoogleMapModalComponent,
        VersionInfoComponent,
        ConfirmModalComponent,
        ResultModalComponent,
        ServiceOutageDialogComponent,
        ReleaseNotesComponent,
        UserDataComponent,
        NotFoundComponent,
        UserAvatarComponent,
        OrderTypeLabelPipe,
        StatusCodePipe,
        PriorityLabelPipe,
        RbacShowDirective,
        MiddleclickDirective,
        MaintenanceDialogComponent,
        DateTimeFormatPipe,
        CompanyMenuOptionsLabelPipe,
        UserMappingRoleLabelPipe,
        UserMappingTypeLabelPipe,
        SessionExpiredDialogComponent,
        CompaniesComponent,
        CompanyModalComponent,
        CompanyOptionsMenuComponent,
        CreateMessageDialogComponent,
        UserMappingComponent,
        CreateUserMappingModalComponent,
        DeleteCompanyModalComponent,
    ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    HttpClientModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSelectModule,
    MatTooltipModule,
    GoogleMapsModule,
    ClipboardModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
  ],
    bootstrap: [],
    exports: [
        ErrorModalComponent,
        SpinnerModalComponent,
        DocumentDeleteModalComponent,
        DocumentUploadModalComponent,
        DocumentsComponent,
        DocumentFilenameChangeModalComponent,
        GoogleMapModalComponent,
        VersionInfoComponent,
        ConfirmModalComponent,
        ResultModalComponent,
        ServiceOutageDialogComponent,
        UserAvatarComponent,
        PriorityLabelPipe,
        OrderTypeLabelPipe,
        StatusCodePipe,
        RbacShowDirective,
        MiddleclickDirective,
        MaintenanceDialogComponent,
    ],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [
                MAT_DATE_LOCALE
            ]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.YYYY',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'DD.MM.YYYY',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms'
            }
        },
        PriorityLabelPipe
    ]
})
export class SharedModule { }
