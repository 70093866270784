import { Injectable } from '@angular/core';
import { MessagesService, MessageTOV2 } from '../../api/generated/v2/incident';
import { EnvironmentService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesDataService {

  constructor(private messagesService: MessagesService) {
    this.messagesService.configuration.basePath = EnvironmentService.getEnvironment().incidentBasePath;
  }

  public createMessage(incidentUuid: string, messageTO: MessageTOV2) {
    return this.messagesService.createMessageV2(incidentUuid, messageTO);
  }

  public getAllMessages(incidentUuid: string) {
    return this.messagesService.getMessagesForIncidentV2(incidentUuid);
  }

  public makeTheMessageDoneOrUndone(messageUuid: string, isDone: boolean) {
    return this.messagesService.makeTheMessageDoneOrUndone(messageUuid, isDone);
  }
}
