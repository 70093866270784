<mat-dialog-content>
  <div class='cls-div'>
    <button id='clsBtn' class='cls-btn' mat-icon-button (click)='onClsBtnClick()'>
      <mat-icon style='margin: 0px;'>close</mat-icon>
    </button>
  </div>
  <div class="model-header">
    <h2 id='message'>Ordne den externen DL der entsprechenden Firma oder internen Kollegen der Abteilung zu: </h2>
  </div>
  <div class='col' style='width: 600px; overflow: auto; margin-bottom: 10px;'>
    <form id='form-report' [formGroup]='createUserMappingForm'>
      <mat-form-field style='width: 100%;'>
        <input #userName id='input-userName' matInput required
               formControlName='userName'
               [matAutocomplete]="userNameAutoOption"
               maxlength='100' placeholder='Vor- Nachname*'
               (focus)="this.onUserInputFocus()"/>
        <mat-icon matSuffix class="dropdown-icon" (click)="userName.focus()">arrow_drop_down</mat-icon>
        <mat-autocomplete #userNameAutoOption class="user-autocomplete">
          <mat-option *ngFor="let user of autocompleteUser$ | async"
                      [class.internalUser]="this.isUserInternal(user)"
                      [class.externalUser]="!this.isUserInternal(user)"
                      [value]="getUserName(user)"
                      (onSelectionChange)="onUserSelect(user, $event.isUserInput)">
            {{this.isUserInternal(user) ? '(Intern)' : '(Extern)'}} - {{ user.firstname }} {{ user.lastname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field style='width: 100%;' *ngIf="this.isUserTypeExternal()">
        <input #companyName id='input-companyName' matInput required
               formControlName='companyName'
               [matAutocomplete]="companyAutoOption"
               placeholder='Firma'
               (focus)="this.onCompanyInputFocus()"/>
        <mat-icon matSuffix class="dropdown-icon" (click)="userName.focus()">arrow_drop_down</mat-icon>
        <mat-autocomplete #companyAutoOption class="company-autocomplete">
          <mat-option *ngFor="let company of autocompleteCompany$ | async" [value]="company.companyName" (onSelectionChange)="onCompanySelect(company , $event.isUserInput)">
            {{ company.companyName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field style='width: 100%;' *ngIf="this.isUserTypeInternal()">
        <mat-select id="input-role" required name="role"
                    formControlName="role"
                    (selectionChange)="setUserMappingRole($event)"
                    placeholder='Rolle*'>
          <mat-option *ngFor="let role of userMappingTypes" [value]="role.key">
            {{ role.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <input #email id='input-email' matInput required formControlName='email'
               maxlength='100' placeholder='E-Mail Adresse*' />
      </mat-form-field>
    </form>
  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <div class='div-stl' style='padding-bottom: 30px;'>
    <button id='btn1' class='nd-btn nd-btn-outline btn-fnt btn-spc' style='width: 160px;'
            mat-raised-button (click)='onRevokeBtnClick()'> Abbrechen
    </button>
    <button id='btn2' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 160px;'
            [disabled]='!createUserMappingForm.valid'
            mat-raised-button (click)='onConfirmBtnClick()'> Speichern
    </button>
  </div>
</mat-dialog-actions>
