/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserMappingTO } from './userMappingTO';


/**
 * Company object
 */
export interface CompanyTO { 
    /**
     * Company UUID
     */
    uuid?: string;
    /**
     * Name of company
     */
    companyName: string;
    /**
     * Ort
     */
    city: string;
    /**
     * Company address
     */
    address: string;
    /**
     * Zip code
     */
    zipCode: string;
    /**
     * User mappings
     */
    userMappings?: Array<UserMappingTO>;
}

