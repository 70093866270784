import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { CompanyModalComponent } from '../create-company-modal/company-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService, CompanyTO } from '../../../api/generated/v2/incident';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsMenuOptionType } from '../../models/enums/company/actions-menu-option-type';
import { CompanyDataModalImpl } from './company-data-modal.impl';
import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Spinner } from '../spinner-modal/spinner';
import { NavigationService } from '../../services/navigation.service';
import { CompanyDataService } from '../../services/company-data.service';
import { DeleteCompanyModalComponent } from '../delete-company-modal/delete-company-modal.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  private paginator: MatPaginator;

  @ViewChild('paginator', { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  dataSource: MatTableDataSource<CompanyTO> =
    new MatTableDataSource();

  public displayedColumns: string[] = [
    'companyName',
    'city',
    'address',
    'zipCode',
    'actions',
  ];
  pageIndex = 0;
  pageSize = 10;
  totalRows = 0;

  filter$: BehaviorSubject<any> = new BehaviorSubject(null);
  pagination$: BehaviorSubject<any> = new BehaviorSubject(null);
  companies$: BehaviorSubject<CompanyTO[]> = new BehaviorSubject([]);

  destroyed$: Subject<any> = new Subject();

  ngOnInit(): void {
    this.companies$.subscribe((companies: CompanyTO[]) => {
      this.dataSource.data = companies;
    });

    this.route.queryParamMap.subscribe(
      (params) => {
        this.pageSize = params.get('pageSize') ? Number(params.get('pageSize')) : 10;
        this.pageIndex = params.get('pageIndex') ? Number(params.get('pageIndex')) : 0;
        this.pagination$.next({ pageSize: this.pageSize, pageIndex: this.pageIndex });
      });

    this.filter$.subscribe(_ => {
      // reset pageIndex on filter change
      this.router.navigate([], {
        queryParams: {
          pageIndex: 0,
          pageSize: this.pageSize,
        },
        queryParamsHandling: 'merge',
      });
    });

    merge(this.filter$, this.pagination$)
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(250),
      )
      .subscribe(async () => {

        const chunk = await this.companyDataService
          .getAllCompanies(this.pageIndex, this.pageSize)
          .toPromise() as any;
        this.totalRows = chunk?.totalCount || 0;

        setTimeout(() => {
          this.paginator.pageIndex = chunk?.totalCount ? this.pageIndex : 0;
          this.paginator.length = chunk?.totalCount || 0;
        });

        this.companies$.next(chunk?.data || []);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private navigationService: NavigationService,
              private companyDataService: CompanyDataService) {
  }

  public handlePageChange(event: PageEvent) {
    const spinner = new Spinner();
    spinner.show(this.dialog, { message: 'Laden...' });

    this.router.navigate([], {
      queryParams: {
        pageIndex: event.pageIndex,
        pageSize: event.pageSize,
      },
      queryParamsHandling: 'merge',
    });

    this.companies$.subscribe((rules) => {
      this.dataSource.data = rules;
      spinner.close();
    });
  }

  public openAddCompanyDialog(): void {

    const data: CompanyDataModalImpl = {
      operation: ActionsModalOperationType.CREATE,
    };

    const dialogRef = this.dialog.open(CompanyModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((res: DialogResult) => {
      if (DialogResult.SUCCESS === res) {
        this.loadCompanies();
      }
    });
  }

  public ruleOptions(): ActionsMenuOptionType[] {
    return [
      ActionsMenuOptionType.EDIT,
      ActionsMenuOptionType.DELETE,
    ];
  }

  public handleOptionClicked(
    companyTO: CompanyTO,
    option: ActionsMenuOptionType,
  ) {
    switch (option) {
      case ActionsMenuOptionType.EDIT: {
        this.handleOptionEdit(companyTO);
        break;
      }
      case ActionsMenuOptionType.DELETE: {
        this.handleOptionDelete(companyTO.uuid);
        break;
      }
    }
  }

  public onBackBtnClick(): void {
    this.navigationService.navigateToIncidentList();
  }

  private handleOptionEdit(companyTo: CompanyTO): void {
    const data: CompanyDataModalImpl = {
      operation: ActionsModalOperationType.UPDATE,
      uuid: companyTo.uuid,
      companyName: companyTo.companyName,
      city: companyTo.city,
      zipCode: companyTo.zipCode,
      address: companyTo.address,
    };

    const dialogRef = this.dialog.open(CompanyModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((res: DialogResult) => {
      if (DialogResult.SUCCESS === res) {
        this.loadCompanies();
      }
    });
  }

  private handleOptionDelete(companyUuid: string): void {
    const data: any = {
      companyUuid
    };

    const dialogRef = this.dialog.open(DeleteCompanyModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((res: DialogResult) => {
      if (DialogResult.SUCCESS === res) {
        this.loadCompanies();
      }
    });
  }

  private loadCompanies(): void {
    this.companyDataService.getAllCompanies(this.pageIndex, this.pageSize).subscribe(result => {
      this.dataSource.data = result.data || [];
    });
  }
}
