import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionsMenuOptionType } from '../../../models/enums/company/actions-menu-option-type';

@Component({
  selector: 'company-options-menu',
  templateUrl: './company-options-menu.component.html',
  styleUrls: ['./company-options-menu.component.scss'],
})
export class CompanyOptionsMenuComponent {

  @Input() options: ActionsMenuOptionType[];
  @Output() optionClicked: EventEmitter<ActionsMenuOptionType> = new EventEmitter();

  constructor() {
  }

  handleOptionClick(option: ActionsMenuOptionType) {
    this.optionClicked.emit(option);
  }
}
