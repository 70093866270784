<div class="container">
  <div class="page-header">
    <h1 id="userDataH1">Meine Einstellungen</h1>
  </div>
  <div class="col">
    <div class="head-data row">
      <div class="col-sm" style="max-width: 33%; padding: 0px;">
        <form [formGroup]="userSettingsForm">
          <mat-form-field style="width: 295px;">
            <mat-label>User-ID</mat-label>
            <input id="input-setting-userid" matInput formControlName="userId">
          </mat-form-field>
          <br>
          <mat-form-field style="width: 295px;">
            <mat-label>Username</mat-label>
            <input id="input-setting-username" matInput formControlName="username">
          </mat-form-field>
          <br>
        </form>
      </div>
      <div class="col-sm" style="max-width: 33%; padding: 0px;">
        <form [formGroup]="userSettingsForm">
          <mat-form-field style="width: 295px;">
            <mat-label>Vorname</mat-label>
            <input id="input-setting-firstname" matInput formControlName="firstname">
          </mat-form-field>
          <br>
          <mat-form-field style="width: 295px;">
            <mat-label>Nachname</mat-label>
            <input id="input-setting-lastname" matInput formControlName="lastname">
          </mat-form-field>
        </form>
      </div>
      <div class="col-sm" style="max-width: 33%; padding: 0px;">
        <form [formGroup]="userSettingsForm">
          <mat-form-field style="width: 295px;">
            <mat-label>Primäre Rolle</mat-label>
            <input id="input-setting-primaryrole" matInput formControlName="primaryRole">
          </mat-form-field>
          <br>
          <mat-form-field style="width: 295px;">
            <mat-label>Rollen</mat-label>
            <input id="input-setting-roles" matInput formControlName="roles">
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-sm" style="max-width: 50%; padding: 0px;">
        <form [formGroup]="userSettingsForm">
          <mat-form-field style="width: 350px;">
            <mat-label>Netzgebiet</mat-label>
            <mat-select id="input-setting-netarea" name="netArea" formControlName="netArea">
              <mat-option>{{ dropdownDefaultSelection }}</mat-option>
              <mat-option *ngFor="let netArea of netAreas" [value]="netArea.key">
                {{ netArea.selectLabel }}</mat-option>
            </mat-select>
            <mat-error id="emailErr">
              Bitte füllen Sie dieses Feld aus.
            </mat-error>
          </mat-form-field>
          <br>
          <div class="row" style="padding-left: 15px;">
            <mat-form-field style="width: 326px;">
              <mat-label>Neuer Email-Favorit</mat-label>
              <input id="input-email-favorite-to-add"
                     matInput formControlName="emailFavoriteToAdd"
                     [matAutocomplete]="usersAutocomplete"
                     placeholder="Email hinzufügen...">
              <mat-error *ngIf="emailFavoriteToAdd.errors?.email && emailFavoriteToAdd.touched">Die eingegebene Email Adresse ist nicht gültig.</mat-error>
              <mat-autocomplete #usersAutocomplete class="users-autocomplete">
                <mat-option *ngFor="let user of autocompletedUsersEmailOptions$ | async" [value]="user.mail"
                            [class.internalUser]="this.isUserInternal(user)"
                            [class.externalUser]="!this.isUserInternal(user)">
                  {{this.isUserInternal(user) ? '(Intern)' : '(Extern)'}} - {{user.mail}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button id="btnadd"
              mat-icon-button
              class="primary-actn-btn"
              (click)="addEmailFavorite()">
              <mat-icon id="plsIcn" svgIcon="dcplus"></mat-icon>
            </button>
          </div>
          <div>
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              matSortDirection="asc"
              matSortActive="email"
              matSortDisableClear
              *ngIf="dataSource.data.length > 0"
            >
              <ng-container matColumnDef="email">
                <th id="emailTh"
                mat-header-cell
                *matHeaderCellDef
                i18n="@@email.address">Email-Favorit</th>
                <td id="emailTd" mat-cell *matCellDef="let element">
                  {{ element }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th
                  id="actionsTh"
                  mat-header-cell
                  *matHeaderCellDef
                  [attr.colspan]="1"
                  i18n="@@actions">Aktionen</th>
                <td id="actionsTd" mat-cell *matCellDef="let element">
                  <div class="row" style="margin: 0; display: flex; align-items: center;">
                    <button
                      id="deleteBtn"
                      mat-icon-button
                      class="actbtn"
                      type="button"
                      matTooltip="Favorit löschen"
                      matTooltipShowDelay="1000"
                      matTooltipPosition="above"
                      (click)="removeEmailFavorite(element)">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <div class="col div-stl" style="width: 585px; justify-content: flex-end; padding: 0px; margin: 0px;">
            <mat-paginator
              id="emailsPaginator"
              [pageSize]="4"
              [showFirstLastButtons]="false"
              [hidePageSize]="true"
              *ngIf="dataSource.data.length > 0"
            >
            </mat-paginator>
          </div>
        </form>
      </div>
    </div>
    <div class="row">

      <a id="btnback"
        mat-raised-button
        class="nd-btn nd-btn-outline btn-fnt btn-spc-leg"
        (click)="onBackBtnClick()"
        i18n="@@button.back">Zurück</a>
      <a id="btnsave"
         mat-raised-button
         class="nd-btn nd-btn-main btn-fnt btn-spc-leg"
         color='accent'
         (click)="saveUserSettings()"
         i18n="@@button.save"
         *ngIf="UserRoleUtils.hasRoleOperator(loggedInUser)">Speichern</a>
    </div>
  </div>
</div>
