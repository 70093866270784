/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyTO } from './companyTO';
import { UserMappingRole } from './userMappingRole';
import { UserMappingType } from './userMappingType';


/**
 * User mapping object
 */
export interface UserMappingTO { 
    /**
     * User mapping UUID
     */
    uuid?: string;
    company?: CompanyTO;
    /**
     * User name
     */
    userName: string;
    /**
     * User email
     */
    email?: string;
    role?: UserMappingRole;
    type?: UserMappingType;
}
export namespace UserMappingTO {
}


