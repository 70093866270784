<div class="container">
  <h2 class="title">Schreiben Sie den Empfänger, Ihre Nachricht und Datum, bis wann die unverbindliche Anfrage bearbeitet werden sollte, hier ein:</h2>
  <form [formGroup]="messageCreationFormGroup" class="main">
    <div class="field-content">
      <h4>An</h4>
      <mat-form-field appearance="outline">
        <mat-label>Empfänger (kann auch man selbst sein)</mat-label>
        <mat-chip-grid
          #usersChipList
          aria-label="Emails"
          formControlName="userName"
          multiple
          required>
          <mat-chip-row
            *ngFor="let user of selectedUsers; let i = index"
            selectable="true"
            removable="true"
            (removed)="onUserRemove(i)">
            {{ user.firstname }} {{ user.lastname }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            name="emails"
            formControlName="userName"
            matInput
            #usersInput
            [matChipInputFor]="usersChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="false"
            [matAutocomplete]="userNameAutoOption">
        </mat-chip-grid>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #userNameAutoOption (optionSelected)="onUserSelected($event)">
          <mat-option *ngFor="let user of autocompleteUser$ | async"
                      [class.internalUser]="this.isUserInternal(user)"
                      [class.externalUser]="!this.isUserInternal(user)"
                      [value]="user">
            {{this.isUserInternal(user) ? '(Intern)' : '(Extern)'}} - {{ user.firstname }} {{ user.lastname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="field-content">
      <div class="content-header">
        <h4>Information</h4>
        <span style="color: #979797; text-align: right;"> {{ currentMessageContentLength }} / {{ maximumMessageContentLength }} Zeichen</span>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Ihr Text</mat-label>
        <textarea
          matInput
          formControlName="message"
          [maxlength]="maximumMessageContentLength"
          [errorStateMatcher]="matcher"
          rows="6"
          required>
            </textarea>
        <mat-hint *ngIf="!validationService.latinLettersAndPunctuationValidator(messageCreationFormGroup.controls.message.value)">Text/Meldung wird ohne Sonderzeichen gespeichert</mat-hint>
      </mat-form-field>
    </div>

    <div class="field-content">
      <h4>Zu bearbeiten bis</h4>
      <mat-form-field appearance="outline">
        <mat-label>Datum</mat-label>
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <input [min]="nowDate" matInput [matDatepicker]="picker" formControlName="wishResolutionDate" readonly>
      </mat-form-field>
    </div>
  </form>

  <mat-dialog-actions>
    <div class='div-stl' style='padding-bottom: 30px;'>
      <button id='btn1' class='nd-btn nd-btn-outline btn-fnt btn-spc' style='width: 160px;'
              mat-raised-button (click)="onClsBtnClick()"> Abbrechen
      </button>
      <button id='btn2' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 220px;'
              mat-raised-button (click)="onConfirm(true)"> Speichern und Mail senden
      </button>
      <button id='btn3' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 160px;'
              mat-raised-button (click)="onConfirm(false)"> Nur Speichern
      </button>
    </div>
  </mat-dialog-actions>
</div>
