/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User Mapping Type
 */
export type UserMappingType = 'EXTERNAL_USER' | 'INTERNAL_USER';

export const UserMappingType = {
    ExternalUser: 'EXTERNAL_USER' as UserMappingType,
    InternalUser: 'INTERNAL_USER' as UserMappingType
};

