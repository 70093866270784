import { Pipe, PipeTransform } from '@angular/core';
import { UserMappingType } from '../../api/generated/v2/incident';

@Pipe({
  name: 'userMappingTypeLabel',
})
export class UserMappingTypeLabelPipe implements PipeTransform {

  transform(value: UserMappingType): string {
    switch (value) {
      case UserMappingType.InternalUser:
        return 'Intern';
      case UserMappingType.ExternalUser:
        return 'Extern';
      default:
        return '-';
    }
  }

}
