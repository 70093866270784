import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  latinLettersAndPunctuationValidator(value): boolean {
    const pattern = /^[a-zA-ZäöüÄÖÜß0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
    const valid = pattern.test(value);
    return valid;
  }
}
