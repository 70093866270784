<mat-dialog-content *ngIf='company$ | async'>
  <div class='cls-div'>
    <button id='clsBtn' class='cls-btn' mat-icon-button (click)='onClsBtnClick()'>
      <mat-icon style='margin: 0px;'>close</mat-icon>
    </button>
  </div>
  <div class='model-header'>
    <h2 id='message'>Firmeneintrag löschen</h2>
  </div>
  <div mat-dialog-content class='model-body'>
    <div class='col' style='margin-bottom: 10px;'>
      <div class='row'>
        <div class='modal-body'>
          <div id='top-hint' class='hint-box'>Mit der Löschung des Firmeneintrags werden auch die dazugehörigen Personeneinträge gelöscht:</div>
          <ul>
            <li *ngFor='let user of companyData.userMappings'>
              {{ user.userName }}  -  {{user.email}}
            </li>
          </ul>
          <div id='bottom-hint' class='hint-box-bottom'>Wollen Sie den Firmeneintrag <b>{{companyData.companyName}}</b> und die Personeneinträge löschen?</div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class='div-stl' style='padding-bottom: 30px;'>
    <button id='btn1' class='nd-btn nd-btn-outline btn-fnt btn-spc' style='width: 160px;'
            mat-raised-button (click)='onRevokeBtnClick()'>Nein
    </button>
    <button id='btn2' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 160px;'
            mat-raised-button (click)='onConfirmBtnClick()'> Ja, löschen
    </button>
  </div>
</mat-dialog-actions>
