import { ObjUtils } from './obj.utils';
import { ReporterType, UserMappingRole } from '../../api/generated/v2/incident';

export class UserMappingRoleUtils {
  public static readonly USER_MAPPING_TYPES = [
    {
      key: UserMappingRole.ConstructionCoordination,
      label: 'Baukoordination'
    },
    {
      key: UserMappingRole.OperationsService,
      label: 'Betriebsservice'
    },
    {
      key: UserMappingRole.ExternalServiceProvider,
      label: 'Externer Dienstleister'
    },
    {
      key: UserMappingRole.TechnicalSales,
      label: 'Fachvertrieb'
    },
    {
      key: UserMappingRole.TechnicalInsideSales,
      label: 'Fachvertrieb Innendienst'
    },
    {
      key: UserMappingRole.InternalInstaller,
      label: 'Interner Monteur'
    },
    {
      key: UserMappingRole.Foreman,
      label: 'Meister'
    },
    {
      key: UserMappingRole.ProjectPlanning,
      label: 'Projektierung'
    }
  ];

  public static getUserMappingType(userMappingType: UserMappingRole | string) {
    const element = UserMappingRoleUtils.USER_MAPPING_TYPES.find(elem => elem.key === userMappingType);
    return ObjUtils.isNullOrUndefined(element) ? null : element;
  }

  public static getUserMappingKey(userMappingType: UserMappingRole | string): UserMappingRole {
    const element = UserMappingRoleUtils.getUserMappingType(userMappingType);
    return ObjUtils.isNullOrUndefined(element) ? null : element.key;
  }
}
