import { Component, Inject, OnInit } from '@angular/core';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyDataService } from '../../services/company-data.service';
import { MatIconRegistry } from '@angular/material/icon';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../api/technical-data';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';
import { CompanyTO, DiConUserTO, UserMappingTO, UserMappingType } from '../../../api/generated/v2/incident';
import { MatSelectChange } from '@angular/material/select';
import { UserMappingDataService } from '../../services/user-mapping-data.service';
import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';
import { UserMappingDataModalImpl } from '../user-mapping/user-mapping-data-modal.impl';
import { UserMappingRoleUtils } from '../../services/user-mapping-role.utils';
import { Spinner } from '../spinner-modal/spinner';

@Component({
  selector: 'app-create-user-mapping-modal',
  templateUrl: './create-user-mapping-modal.component.html',
  styleUrls: ['./create-user-mapping-modal.component.scss'],
})
export class CreateUserMappingModalComponent implements OnInit {

  public readonly userMappingTypes: any[] = UserMappingRoleUtils.USER_MAPPING_TYPES;

  private company: CompanyTO;
  private usersList: any[];
  private companiesList: CompanyTO[];

  public createUserMappingForm: UntypedFormGroup = new UntypedFormGroup({
    userName: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    companyName: new UntypedFormControl('', [] ),
    type: new UntypedFormControl('', [Validators.required]),
    role: new UntypedFormControl('', []),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  autocompleteUser$: Observable<any[]> = this.createUserMappingForm
    .get('userName').valueChanges
    .pipe(
      mergeMap((userName) => this.userFilter(userName)),
    );

  autocompleteCompany$: Observable<CompanyTO[]> = this.createUserMappingForm
    .get('companyName').valueChanges
    .pipe(
      mergeMap((company) => this.companyFilter(company)),
    );

  constructor(
    public dialogRef: MatDialogRef<CreateUserMappingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserMappingDataModalImpl,
    protected sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private userService: UserService,
    private readonly companyDataService: CompanyDataService,
    private readonly userMappingDataService: UserMappingDataService,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('qm', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-mark.svg'));
  }

  ngOnInit() {
    if (this.data.operation === ActionsModalOperationType.UPDATE) {
      this.createUserMappingForm.patchValue({
        userName: this.data.userName,
        companyName: this.data.company?.companyName,
        role: this.data.role,
        type: this.data.type,
        email: this.data.email,
      });
      this.company = this.data.company;
    }

    const loadDataObs = forkJoin({
        users: this.getAllUsers(),
        companies: this.getAllCompanies(),
      });
    new Spinner().spin(loadDataObs, this.dialog, { message: 'Lädt Nutzereinstellungen...' });

    loadDataObs.subscribe(({ users, companies }) => {
      this.usersList = users;
      this.companiesList = companies;
    });
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public onRevokeBtnClick(): void {
    this.close(DialogResult.NO);
  }

  private close(res: DialogResult): void {
    this.dialogRef.close(res);
  }

  public onConfirmBtnClick(): void {
    if (this.data.operation === ActionsModalOperationType.CREATE) {
      this.createUserMapping();
    } else if (this.data.operation === ActionsModalOperationType.UPDATE) {
      this.updateUserMapping();
    }
  }

  private updateUserMapping() {
    const updateUserMapping: UserMappingTO = {
      userName: this.createUserMappingForm.get('userName').value,
      type: this.createUserMappingForm.get('type').value,
      email: this.createUserMappingForm.get('email').value,
    };

    if (updateUserMapping.type === UserMappingType.InternalUser) {
      updateUserMapping.role = this.createUserMappingForm.get('role').value;
    } else {
      updateUserMapping.company = this.company;
    }

    this.userMappingDataService.updateUserMapping(this.data.uuid, updateUserMapping).subscribe((response: any) => {
      const isSuccess: boolean = 200 === response.status;
      this.close(isSuccess ? DialogResult.SUCCESS : DialogResult.FAIL);
    }, (error: any) => {
      this.close(DialogResult.FAIL);
    });
  }

  private createUserMapping(): void {

    const data: UserMappingTO = {
      userName: this.createUserMappingForm.get('userName').value,
      type: this.createUserMappingForm.get('type').value,
      email: this.createUserMappingForm.get('email').value,
    };

    if (data.type === UserMappingType.InternalUser) {
      data.role = this.createUserMappingForm.get('role').value;
    } else {
      data.company = this.company;
    }

    this.userMappingDataService.createUserMapping(data).subscribe((response: any) => {
      const isSuccess: boolean = 200 === response.status;
      this.close(isSuccess ? DialogResult.SUCCESS : DialogResult.FAIL);
    }, (error: any) => {
      this.close(DialogResult.FAIL);
    });
  }

  public setUserMappingRole(event: MatSelectChange): void {
    this.createUserMappingForm.get('role').patchValue(event.value);
    this.createUserMappingForm.get('companyName').patchValue('');
  }

  public onUserSelect(user: any, isUserSelected: boolean) {
    if (!isUserSelected) {
      return;
    }

    if (user == null) {
      this.createUserMappingForm.get('userName').setErrors({ incorrect: true });
    } else {
      this.createUserMappingForm.get('userName').patchValue(user?.firstname + ' ' + user?.lastname);
      this.createUserMappingForm.get('email').patchValue(user?.mail.toLowerCase());
      this.createUserMappingForm.get('type').patchValue(user?.type);
      this.updateValidators(user?.type);
    }
  }

  public onCompanySelect(company: CompanyTO | null, isCompanySelected: boolean) {
    if (!isCompanySelected) {
      return;
    }

    if (company == null) {
      this.createUserMappingForm.get('companyName').setErrors({ incorrect: true });
    } else {
      this.createUserMappingForm.get('role').patchValue('');
      this.createUserMappingForm.get('companyName').patchValue(company.companyName);
      this.company = company;
    }
  }

  userFilter(userName: string): Observable<any> {
    return of(this.usersList.filter(user => (user.firstname + ' ' + user.lastname).toLowerCase().includes(userName.toLowerCase())));
  }

  companyFilter(companyName: string): Observable<CompanyTO[]> {
    return of(this.companiesList.filter(company => company.companyName.toLowerCase().includes(companyName.toLowerCase())));
  }

  onUserInputFocus() {
    const userNameValue = this.createUserMappingForm.get('userName');
    if (userNameValue.value === '') {
      userNameValue.setValue('');
    } else {
      userNameValue.setValue(userNameValue.value);
    }
  }

  onCompanyInputFocus() {
    const companyNameValue = this.createUserMappingForm.get('companyName');
    if (companyNameValue.value === '') {
      companyNameValue.setValue('');
    } else {
      companyNameValue.setValue(companyNameValue.value);
    }
  }

  getAllUsers(): Observable<any[]> {
    return forkJoin({
      externalUsers: this.userService.findDiconUsersOfExternalGroup(),
      internalUsers: this.userService.findDiconUsersOfControlOrganization()
    }).pipe(
      map(({ internalUsers, externalUsers }) => {
        const internalUsersList = internalUsers.data.map(user => ({
          ...user,
          type: UserMappingType.InternalUser
        }));
        const externalUsersList = externalUsers.data.map(user => ({
          ...user,
          type: UserMappingType.ExternalUser
        }));
        return [...internalUsersList, ...externalUsersList];
      })
    ).pipe(share());
  }

  getAllCompanies(): Observable<CompanyTO[]> {
    return this.companyDataService.getAllCompanies(0, 100).pipe(
      map((companiesResult) => {
        return companiesResult.data;
      })
    ).pipe(share());
  }

  getUserName(user: DiConUserTO) {
    return user.firstname + ' ' + user.lastname;
  }

  isUserInternal(user: any) {
    return user.type === UserMappingType.InternalUser;
  }

  isUserTypeInternal() {
    return this.createUserMappingForm.get('type').value === UserMappingType.InternalUser;
  }

  isUserTypeExternal() {
    return this.createUserMappingForm.get('type').value === UserMappingType.ExternalUser;
  }

  private updateValidators(type: UserMappingType) {
    if (type === UserMappingType.InternalUser) {
      this.createUserMappingForm.get('companyName').clearValidators();
      this.createUserMappingForm.get('role').setValidators([Validators.required]);
    } else if (type === UserMappingType.ExternalUser) {
      this.createUserMappingForm.get('role').clearValidators();
      this.createUserMappingForm.get('companyName').setValidators([Validators.required]);
    }
  }
}
