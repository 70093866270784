<div class='container'>
  <div class='page-header'>
    <h1 id='userDataH1'>Firmeneinstellungen</h1>
  </div>
  <div>
    <button mat-raised-button color='accent'
            (click)='openAddCompanyDialog()'
    >
      <mat-icon>add</mat-icon>
      Neuer Eutrag
    </button>
  </div>
  <div class='companies-table'>
    <table mat-table [dataSource]='dataSource'>
      <ng-container matColumnDef='companyName'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Firmenname</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span [matTooltip]='element.companyName' matTooltipShowDelay='1000' matTooltipPosition='above'>
            {{ element.companyName }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='city'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Ort</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span [matTooltip]='element.city' matTooltipShowDelay='1000' matTooltipPosition='above'>
            {{ element.city }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='address'>
        <th mat-header-cell *matHeaderCellDef>
          <span>Straße und Hausnummer</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span [matTooltip]='element.address' matTooltipShowDelay='1000' matTooltipPosition='above'>
            {{ element.address }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='zipCode'>
        <th mat-header-cell *matHeaderCellDef>
          <span>PLZ</span>
        </th>
        <td mat-cell *matCellDef='let element'>
          <span [matTooltip]='element.zipCode' matTooltipShowDelay='1000' matTooltipPosition='above'>
            {{ element.zipCode }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef='actions'>
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef='let element'>
          <company-options-menu [options]='ruleOptions()'
                                (optionClicked)='handleOptionClicked(element, $event)'></company-options-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
      <tr mat-row *matRowDef='let row; columns: displayedColumns' [class.inactive-rule]='!row.active'></tr>
    </table>
    <mat-paginator
      #paginator
      [length]='totalRows'
      [pageSize]='pageSize'
      [pageSizeOptions]='[5, 10, 15]'
      [showFirstLastButtons]='true'
      [hidePageSize]='false'
      [pageIndex]='pageIndex'
      (page)='handlePageChange($event)'
    ></mat-paginator>
  </div>

  <div class='row'>
    <a id='btnback'
       mat-raised-button
       class='nd-btn nd-btn-outline btn-fnt btn-spc-leg'
       (click)='onBackBtnClick()'
       i18n='@@button.back'>Zurück</a>
  </div>
</div>
