<mat-dialog-content>
  <div class='cls-div'>
    <button id='clsBtn' class='cls-btn' mat-icon-button (click)='onClsBtnClick()'>
      <mat-icon style='margin: 0px;'>close</mat-icon>
    </button>
  </div>
  <div class="model-header">
    <h2 id='message'>Füge eine neue Partnerfirma hinzu:</h2>
  </div>
  <div class='col' style='width: 600px; height: 400px; overflow: auto; margin-bottom: 10px;'>
    <form id='form-report' [formGroup]='createCompanyForm'>
      <mat-form-field style='width: 100%;'>
        <input #companyName id='input-companyName' matInput required formControlName='companyName'
               maxlength='100' placeholder='Firmenname*' />
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <input #city id='input-city' matInput required
               formControlName='city'
               [matAutocomplete]="cityAutoOptions"
               maxlength='100' placeholder='Ort*' />
        <mat-autocomplete #cityAutoOptions class="city-autocomplete">
          <mat-option *ngFor="let commune of autocompletedCityOptions$ | async" [value]="commune.town" (onSelectionChange)="onCommuneSelect(commune)">
            {{ commune.zipCode }} {{ commune.town }} ({{ commune.ags }})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <input #zipCode id='input-zipCode' matInput required formControlName='zipCode'
               maxlength='100' placeholder='PLZ*' />
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <input #address id='input-address' matInput required formControlName='address'
               maxlength='100' placeholder='Straße und Hausnummer*' />
      </mat-form-field>
    </form>
  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <div class='div-stl' style='padding-bottom: 30px;'>
    <button id='btn1' class='nd-btn nd-btn-outline btn-fnt btn-spc' style='width: 160px;'
            mat-raised-button (click)='onRevokeBtnClick()'> Abbrechen
    </button>
    <button id='btn2' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 160px;'
            [disabled]='!createCompanyForm.valid'
            mat-raised-button (click)='onConfirmBtnClick()'> Speichern
    </button>
  </div>
</mat-dialog-actions>
