<div>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="options menu" (click)="$event.stopPropagation()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let option of options" (click)="handleOptionClick(option); $event.stopPropagation();">
      <span>{{ option | optionLabel }}</span>
    </button>
  </mat-menu>
</div>
