import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemTypeUtils } from 'app/incidents/services/system.type.util';
import { DialogResult } from 'app/shared/common/models/enums/dialog-result/dialog.result';
import { Spinner } from 'app/shared/common/components/spinner-modal/spinner';
import { Device } from 'app/shared/common/models/device';
import { LuxDataTechnicalService } from 'app/shared/common/services/ld.technical.service';
import { ObjUtils } from 'app/shared/common/services/obj.utils';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, forkJoin } from 'rxjs';
import { map, mergeMap, debounceTime } from 'rxjs/operators';
import { GoogleMapModalDialogData } from './google-map-modal-dialog.data';
import {
  CommuneTO,
  GeoLocationResponseV2ControlCabinetLocationDataV2,
  GeoLocationResponseV2LightingPointLocationDataV2,
  IncidentStatus,
  SystemType,
} from '../../../api/generated/v2/incident';

@Component({
  selector: 'app-google-map-modal',
  templateUrl: './google-map-modal.component.html',
  styleUrls: ['./google-map-modal.component.scss']
})
export class GoogleMapModalComponent implements OnInit {
  public reportForm: UntypedFormGroup = new UntypedFormGroup({
    location: new UntypedFormGroup({
      street: new UntypedFormControl('', Validators.maxLength(50)),
      houseNo: new UntypedFormControl('', Validators.maxLength(5)),
      zipCode: new UntypedFormControl('', Validators.maxLength(5)),
      city: new UntypedFormControl('', Validators.maxLength(50)),
      cityDistrict: new UntypedFormControl('', Validators.maxLength(50)),
      lightPointNo: new UntypedFormControl('', Validators.maxLength(17)),
      controlCabinetNo: new UntypedFormControl('', Validators.maxLength(12)),
      allNo: new UntypedFormControl('', Validators.maxLength(17)),
      netarea: new UntypedFormControl(''),
      isLightningPointCheck: new UntypedFormControl(false),
      isControlCabinetCheck: new UntypedFormControl(false),
      systemType: new UntypedFormControl('', Validators.required),
      customerScope: new UntypedFormControl('')
    })
  });

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  public infoContent1: string;
  public infoContent2: string;
  public infoContent3: string;

  public devices: Device[];

  public selectedDevice: Device;

  public markers: any[] = [];
  public markersAll: any[] = [];
  public lpMarkers: any[] = [];
  public ccMarkers: any[] = [];
  markersLimit = 800;

  public loaded = false;
  public loading = false;
  public totalCount = 0;
  public pageSize = 0;

  // colors for points on map
  private readonly colorTiefenblau: string = '#000099';
  private readonly colorHorizontorange: string = '#ff9900';
  private readonly colorSelectedBlue: string = '#004eff';
  private readonly colorControlCabinet: string = '#FF7FBC';

  // BW center
  private readonly initCenterLat: number = 48.53223951904002;
  private readonly initCenterLng: number = 8.923548500000003;
  // BW zoom level
  private readonly initZoomLevel: number = 8;

  private readonly  geoDegreesLimit: number = 1;

  private readonly inProgressIncidentStates: IncidentStatus[] = [
    IncidentStatus.New,
    IncidentStatus.ApprovalRequiredByNetzeBw,
    IncidentStatus.OrderPlaced,
    IncidentStatus.Overdue,
    IncidentStatus.CheckFeedback,
    IncidentStatus.FollowUpMeasuresMaterialProcurement,
    IncidentStatus.FollowUpMeasuresCableTestVan,
    IncidentStatus.FollowUpMeasuresMoreDeficiencies,
    IncidentStatus.InternalClarification,
    IncidentStatus.Unrepairable,
    IncidentStatus.CheckFeedbackFollowUpMeasures,
    IncidentStatus.FollowUpMeasureInProgress,
    IncidentStatus.ImminentDanger,
    IncidentStatus.ImminentDangerLiesAtSalesDepartment,
    IncidentStatus.OrderMaterialOpen,
    IncidentStatus.OrderMaterialClosed
  ];

  public isLightningPointChecked = false;
  public isControlCabinetChecked = false;

  public readonly systemTypes: any[] = SystemTypeUtils.SYSTEM_TYPES.filter(elem => !!elem.active);

  public center: google.maps.LatLngLiteral = { lat: this.initCenterLat, lng: this.initCenterLng, };
  public zoom: number = this.initZoomLevel;
  googleAutocompleteService;
  googleGeocoder;

  autocompletedCityOptions$: Observable<any[]> = this.reportForm
    .get('location').get('city').valueChanges
    .pipe(
      debounceTime(300),
      mergeMap((city: string) => this.communeSearch({ city })),
    );

  constructor(
    public dialogRef: MatDialogRef<GoogleMapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GoogleMapModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
    private readonly ldtSvc: LuxDataTechnicalService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog,
    private readonly _ngZone: NgZone,
  ) {
      iconRegistry.addSvgIcon('place', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/place.svg'));

      this.reportForm.get('location').get('isLightningPointCheck').valueChanges.subscribe((lpChecked: boolean) => {
        const cc = this.reportForm.get('location').get('isControlCabinetCheck');
        const lp = this.reportForm.get('location').get('isLightningPointCheck');

        const controlCabinetNo = this.reportForm.get('location').get('controlCabinetNo');
        const lightPointNo = this.reportForm.get('location').get('lightPointNo');

        lightPointNo.setValue('');
        controlCabinetNo.setValue('');

        this.isLightningPointChecked = lpChecked;

        if (lpChecked && !cc.value) {
          lp.disable({ emitEvent: false });
        } else if (!lpChecked && cc.value) {
          cc.disable({ emitEvent: false });
        } else {
          cc.enable({ emitEvent: false });
          lp.enable({ emitEvent: false });
        }
      });

      this.reportForm.get('location').get('isControlCabinetCheck').valueChanges.subscribe((ccChecked: boolean) => {
        const cc = this.reportForm.get('location').get('isControlCabinetCheck');
        const lp = this.reportForm.get('location').get('isLightningPointCheck');

        const controlCabinetNo = this.reportForm.get('location').get('controlCabinetNo');
        const lightPointNo = this.reportForm.get('location').get('lightPointNo');

        lightPointNo.setValue('');
        controlCabinetNo.setValue('');

        this.isControlCabinetChecked = ccChecked;

        if (ccChecked && !lp.value) {
          cc.disable({ emitEvent: false });
        } else if (!ccChecked && lp.value) {
          lp.disable({ emitEvent: false });
        } else {
          cc.enable({ emitEvent: false });
          lp.enable({ emitEvent: false });
        }
      });
  }

  ngOnInit() {
    this.initFormValues();
    this.googleAutocompleteService = new google.maps.places.AutocompleteService();
    this.googleGeocoder = new google.maps.Geocoder();

    this.enableCorrectCheckbox();

    this.checkCityAutocomplete().subscribe(() => this.onSearchBtnClick());
  }

  private enableCorrectCheckbox() {
    const cc = this.reportForm.get('location').get('isControlCabinetCheck');
    const lp = this.reportForm.get('location').get('isLightningPointCheck');

    const lightningPointNo = this.reportForm.get('location').get('lightPointNo');
    const controlCabinetNo = this.reportForm.get('location').get('controlCabinetNo');

    const isCcValueSet = controlCabinetNo.value !== '' && controlCabinetNo.value !== null;
    const isLpValueSet = lightningPointNo.value !== '' && lightningPointNo.value !== null;

    if (isCcValueSet && !isLpValueSet) {
      this.isControlCabinetChecked = true;
      cc.setValue(true, { emitEvent: false });
      cc.disable({ emitEvent: false });
      lp.enable({ emitEvent: false });
    } else {
      this.isLightningPointChecked = true;
      controlCabinetNo.setValue('');
      lp.setValue(true, { emitEvent: false });
      lp.disable({ emitEvent: false });
      cc.enable({ emitEvent: false });
    }
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public onSearchBtnClick(): void {
    this.checkCityAutocomplete().subscribe(() => this.loadData());
  }

  public onTakeOverBtnClick(): void {
    this.close(DialogResult.TAKEOVER);
  }

  public openInfo(marker: MapMarker, infoText1: string, infoText2: string, infoText3: string): void {
    this.infoContent1 = infoText1;
    this.infoContent2 = infoText2;
    this.infoContent3 = infoText3;
    this.infoWindow.open(marker);
  }

  public selectObject(deviceId: string): void {
    // remove old selection if present
    if (!ObjUtils.isNullOrUndefined(this.selectedDevice)) {
      this.removeFromMarkers(this.selectedDevice);
      this.pushToMarkers(this.selectedDevice, false);
    }
    this.selectedDevice = this.devices.find(elem => elem.id === deviceId);
    this.setFormValuesFromSDevice(this.selectedDevice);
    this.syncSelectedDevice();
  }

  private setFormValuesFromSDevice(device: Device) {
    this.reportForm.patchValue({
      location: {
        street: device.street,
        houseNo: device.houseNo,
        zipCode: device.zipCode,
        city: device.city,
        cityDistrict: device.cityDistrict,
        lightPointNo: device.technicalNo,
        controlCabinetNo: device.technicalNo,
        netarea: device.netArea,
        customerScope: device.customerScope,
        allNo: device.technicalNo
      }
    });
  }

  public syncSelectedDevice() {
    if (!this.selectedDevice) { return; }
    const marker = this.markers.find(m => m.deviceId == this.selectedDevice.id);
    if (!marker || marker.selected) { return; }        // do not overwrite as it creates unwanted effect while panning
    this.removeFromMarkers(this.selectedDevice);
    this.pushToMarkers(this.selectedDevice, true);
  }

  private initFormValues(): void {
    if (ObjUtils.isNullOrUndefined(this.data)
    || ObjUtils.isNullOrUndefined(this.data.locationData)) {
      return;
    }
    const hasLightPoint: boolean =
      !ObjUtils.isNullOrUndefinedOrEmpty(this.data.locationData.lightPointNo);
    const hasControlCabinet: boolean =
      !ObjUtils.isNullOrUndefinedOrEmpty(this.data.locationData.controlCabinetNo);
    this.reportForm.patchValue({
      location: {
        street: this.data.locationData.street,
        houseNo: this.data.locationData.houseNo,
        zipCode: this.data.locationData.zipCode,
        city: this.data.locationData.city,
        cityDistrict: this.data.locationData.cityDistrict,
        lightPointNo: this.data.locationData.lightPointNo,
        controlCabinetNo: this.data.locationData.lightPointNo ? null : this.data.locationData.controlCabinetNo,
        allNo: '',
        netarea: this.data.locationData.netArea,
        systemType: hasControlCabinet && !hasLightPoint ?
          SystemType.ControlCabinetPoint : SystemType.LightingPoint,
        customerScope: this.data.locationData.customerScope
      }
    });
  }

  private pickUpFormData() {
    return {
      street: this.reportForm.value.location.street,
      houseNo: this.reportForm.value.location.houseNo,
      zipCode: this.reportForm.value.location.zipCode,
      city: this.reportForm.value.location.city,
      cityDistrict: undefined,
      allNo: this.reportForm.value.location.allNo,
      lightingNo: (this.isLightningPointChecked && this.reportForm.value.location.lightPointNo) ? this.reportForm.value.location.lightPointNo : null, // "" does not work
      controlCabinetNo: (this.isControlCabinetChecked && this.reportForm.value.location.controlCabinetNo) ? this.reportForm.value.location.controlCabinetNo : null, // "" does not work
    };
  }

  communeSearch(params: any): Observable<CommuneTO[]> {
    return this.ldtSvc.getCommune(params).pipe(map(communes => {
      communes.forEach(c => c.ags =  this.sanitizeCustomerScope(c.ags));
      return communes;
    }));
  }

  googleAddressSearch(searchCriteria: google.maps.places.AutocompletionRequest): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable<google.maps.places.AutocompletePrediction[]>(observer => {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(searchCriteria, (places: google.maps.places.AutocompletePrediction[], status: string) => {
        this._ngZone.run(() => {
          observer.next(places);
          observer.complete();
        });
      });
    });
  }

  googleGeocodePlace(address: string): Observable<any> {
    return new Observable<any[]>(observer => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status: string) => {
        this._ngZone.run(() => {
          observer.next(results);
          observer.complete();
        });
      });
    });
  }

  public checkCityAutocomplete(event = null): Observable<any> {
    if (this.reportForm.value.location.allNo) {
      return of(false);
    }
    if (event?.relatedTarget?.tagName === 'MAT-OPTION') {
      // the input was blurred, but the user is still interacting with the component, they've simply
      // selected a mat-option
      return of(true);
    }

    const city = this.reportForm.value.location.city?.trim();
    const zipCode = this.reportForm.value.location.zipCode;
    const customerScope = this.sanitizeCustomerScope(this.reportForm.value.location.customerScope);

    if (!city) {
      this.onCommuneSelect(null);
      return of(false);
    }

    const obs = this.communeSearch({ city })
      .pipe(map((communes: CommuneTO[]) => {
        if (!communes.length) {
          this.onCommuneSelect(null);
          return;
        }

        // sanitize AGS|ZV
        communes.forEach(c => c.ags = this.sanitizeCustomerScope(c.ags));

        // city & ags already matching
        const exactFind = communes.find(_ => _.town.toLocaleLowerCase() === city.toLocaleLowerCase() && _.ags === customerScope);
        if (exactFind) {
          return;
        }

        const cityFinds = communes.filter(_ => _.town.toLocaleLowerCase().includes(city.toLocaleLowerCase()));
        if (cityFinds.length === 1) {
          this.onCommuneSelect(cityFinds[0]);
          return;
        } else if (cityFinds.length > 1) {
          const find = cityFinds.find(_ => _.zipCode === zipCode);
          this.onCommuneSelect(find || cityFinds[0]);
          return;
        }

        this.onCommuneSelect(null);
        return;
      }));

    obs.subscribe(_ => {});

    return obs;
  }

  private sanitizeCustomerScope(customerScope) {
    if (!customerScope) { return customerScope; }
    if (!customerScope.match(/^(AGS|ZV)_/)) {
      customerScope = 'AGS_' + customerScope;
    }        // if no AGS or ZV prefix found, assume its AGS_
    return customerScope;
  }

  public onCommuneSelect(commune: CommuneTO | null) {
    if (commune == null) {
      this.reportForm.get('location').get('city').setErrors({ incorrect: true });
    } else {
      this.reportForm.get('location').get('city').patchValue(commune?.town);
      this.reportForm.get('location').get('customerScope').patchValue(this.sanitizeCustomerScope(commune?.ags));
      this.reportForm.get('location').get('zipCode').patchValue(commune?.zipCode);
    }
  }

  private async loadData(): Promise<any> {
    this.markers = [];
    this.markersAll = [];
    this.selectedDevice = null;
    const resArr: Device[] = [];
    const address = this.pickUpFormData();
    let customerScope = this.reportForm.value.location.customerScope;
    const city = this.reportForm.value.location.city;
    const zipCode = this.reportForm.value.location.zipCode;
    const id = this.isControlCabinetChecked && this.isLightningPointChecked ? this.reportForm.value.location.allNo :
      this.isControlCabinetChecked ? this.reportForm.value.location.controlCabinetNo : this.reportForm.value.location.lightPointNo;

    if (!customerScope && !id) {
      this.mapInteractionChanged();
      return;
    }

    const spinner = new Spinner();
    spinner.show(this.dialog, { message: `Lädt ${!!this.isControlCabinetChecked && this.isLightningPointChecked ? 'Leuchtstellen / Schal' : this.isControlCabinetChecked ? 'Schal' : 'Leuch'}tstellen...`});
    this.loading = true;

    // single light point search, no city/customerScope
    if (id && !customerScope) { await this.ldtSvc[this.getCorrectRequestMethodName()](id).toPromise().then(single => {
        if (single && !customerScope) {
          customerScope = single.customerScope;
        }
      }).catch(error => {
        this.logger.error(error.error.errorMessage);
        spinner.close();
        this.reportForm.get('location').get('lightPointNo')?.reset();
        this.reportForm.get('location').get('controlCabinetNo')?.reset();
        this.reportForm.get('location').get('allNo')?.reset();
        this.reportForm.get('location').updateValueAndValidity();
    });
    }

    if (!customerScope) {
      spinner.close();
      this.loading = false;
      this.mapInteractionChanged();
      return;
    }

    // Correct google address is Street, zipCode, City and then land/country
    const input = [
      (this.adjustStreetName(address.street) + ' ' + address.houseNo).trim(), zipCode, city, 'Germany/Deutschland'
    ].filter(_ => _).join(' ');

    const technicalNo = this.isControlCabinetChecked && this.isLightningPointChecked ? address.allNo : this.isControlCabinetChecked ? address.controlCabinetNo : address.lightingNo;

    const bounds = new google.maps.LatLngBounds();
    const obsGoogle = this.googleAddressSearch({
      // join all relevant address vectors
      input,
      componentRestrictions: {country: 'de'}
    }).pipe(
      mergeMap((places: google.maps.places.AutocompletePrediction[]) => {
        const validPlace = this.getValidPlace(places, city, address);
        const placeAddress = validPlace.description;
        return this.googleGeocodePlace(this.removePLZFromGoogleAddress(placeAddress, city));
    }));


    let obsLightingPoints: Observable<GeoLocationResponseV2LightingPointLocationDataV2>;
    let obsControlCabinetsPoints: Observable<GeoLocationResponseV2ControlCabinetLocationDataV2>;

    if (this.isControlCabinetChecked) {
      obsControlCabinetsPoints = this.ldtSvc.getControlCabinetGeolocation(customerScope);
    }

    if (this.isLightningPointChecked) {
      obsLightingPoints = this.ldtSvc.getLightpointsByCustomerScopeV2(customerScope);
    }

    let mapRequest: Observable<any>;
    if (obsControlCabinetsPoints && obsLightingPoints) {
      mapRequest = this.readAllData(obsLightingPoints, obsControlCabinetsPoints, obsGoogle);
    } else if (obsLightingPoints) {
      mapRequest  = this.readSingleData(obsLightingPoints, obsGoogle);
    } else {
      mapRequest  = this.readSingleData(obsControlCabinetsPoints, obsGoogle);
    }

    mapRequest
      .subscribe(([lightingPoints, googleAdress]) => {

        const geoResult = lightingPoints;
        const ccMarkers: any[] = [];
        const lpMarkers: any[] = [];

        geoResult.data
        .filter(elem => elem.latitude && elem.longitude &&
          elem.latitude - geoResult.centerLatitude >= - this.geoDegreesLimit &&
          elem.latitude - geoResult.centerLatitude <= this.geoDegreesLimit &&
          elem.longitude - geoResult.centerLongitude >= -this.geoDegreesLimit &&
          elem.longitude - geoResult.centerLongitude <= this.geoDegreesLimit)
        .forEach((elem) => {
          const device: Device = new Device(
            [elem.longitude, elem.latitude, elem.number].join(''),
            elem.number,
            elem.longitude,
            elem.latitude,
            elem.street,
            elem.houseNumber,
            elem.zipCode,
            elem.city,
            elem.district,
            elem.cabinetNumber,
            elem.customerScope,
            elem.netArea,
            elem.incident?.incidentStatus
          );
          resArr.push(device);

          elem.cabinetNumber ? lpMarkers.push(this.createMarker(device, false)) : ccMarkers.push(this.createMarker(device, false));
          bounds.extend(new google.maps.LatLng(elem.latitude, elem.longitude));
        });
        this.devices = resArr;
        this.lpMarkers = lpMarkers;
        this.ccMarkers = ccMarkers;
        this.markersAll = [...this.lpMarkers, ...this.ccMarkers];

        const device = technicalNo ? this.devices.find(elem => elem.technicalNo === technicalNo) : null;
        if (device) {
          this.selectObject(device.id);
          this.center = { lat: device.latitude, lng: device.longitude };
          this.zoom = 19;
        } else if (address.street?.trim()) {
          this.center = { lat: googleAdress[0].geometry.location.lat(), lng: googleAdress[0].geometry.location.lng() };
          this.zoom = 19;
        } else if (this.markersAll.length) {
          this.map.fitBounds(bounds, 5);
        } else {
          this.center = { lat: googleAdress[0].geometry.location.lat(), lng: googleAdress[0].geometry.location.lng() };
          this.zoom = 14;
        }

        this.loaded = true;
        this.totalCount = geoResult.totalCount;
        this.pageSize = geoResult.totalCount;
        this.loading = false;
        this.mapInteractionChanged();      // trigger map interaction change, usefull when zoom remains on the same position
        spinner.close();
      },
      (error: any) => {
        // case data could not be loaded
        this.logger.log(error);
        this.loaded = true;
        this.totalCount = 0;
        this.loading = false;
        this.mapInteractionChanged();      // trigger map interaction change, usefull when zoom remains on the same position
        spinner.close();
      });
  }

  updateValidators() {
    const lightPointNo = this.reportForm.get('location.lightPointNo');
    const controlCabinetNo = this.reportForm.get('location.controlCabinetNo');
    const allNo = this.reportForm.get('location.allNo');

    if (this.isLightningPointChecked && !this.isControlCabinetChecked) {
      // Only lightPointNo is checked
      lightPointNo?.setValidators([Validators.required]);
      controlCabinetNo?.clearValidators();
      allNo?.clearValidators();
    } else if (!this.isLightningPointChecked && this.isControlCabinetChecked) {
      // Only controlCabinetNo is checked
      controlCabinetNo?.setValidators([Validators.required]);
      lightPointNo?.clearValidators();
      allNo?.clearValidators();
    } else if (this.isLightningPointChecked && this.isControlCabinetChecked) {
      // Both are checked, so allNo should be validated
      allNo?.setValidators([Validators.required]);
      lightPointNo?.clearValidators();
      controlCabinetNo?.clearValidators();
    } else {
      // None of the fields are checked
      lightPointNo?.clearValidators();
      controlCabinetNo?.clearValidators();
      allNo?.clearValidators();
    }

    // Revalidate form controls after applying validators
    lightPointNo?.updateValueAndValidity();
    controlCabinetNo?.updateValueAndValidity();
    allNo?.updateValueAndValidity();
  }

  onCheckboxChange() {
    this.updateValidators();
  }

  private readAllData(lpRequest: Observable<GeoLocationResponseV2LightingPointLocationDataV2>,
                      ccRequest: Observable<GeoLocationResponseV2ControlCabinetLocationDataV2>,
                      obsGoogle): Observable<any> {
    return forkJoin([lpRequest, ccRequest, obsGoogle])
      .pipe(
        map(([lpPoints, ccPoints, obsGoogleResponse]:
               [
                 GeoLocationResponseV2LightingPointLocationDataV2,
                 GeoLocationResponseV2ControlCabinetLocationDataV2,
                 any
               ]) => {
          const mergedLightningPoints: GeoLocationResponseV2ControlCabinetLocationDataV2 = {
            totalCount: lpPoints.totalCount + ccPoints.totalCount,
            centerLatitude: lpPoints.centerLatitude,
            centerLongitude: lpPoints.centerLongitude,
            customerScope: lpPoints.customerScope,
            data: [...lpPoints.data, ...ccPoints.data]
          };
          return [mergedLightningPoints, obsGoogleResponse];
        })
      );
  }

  private readSingleData(dataRequest: Observable<GeoLocationResponseV2LightingPointLocationDataV2>, obsGoogle): Observable<any> {
    return forkJoin([dataRequest, obsGoogle]);
  }

  mapInteractionChanged() {
    if (!this.map) { return; }

    const northEast = this.map.getBounds().getNorthEast();
    const southWest = this.map.getBounds().getSouthWest();
    const northEastLong = northEast.lng();
    const northEastLat = northEast.lat();
    const southWestLong = southWest.lng();
    const southWestLat = southWest.lat();

    // check which marker is in the bounding box
    // show markers only if total marker in bounding box is not exceeding limit
    const markersIn = [];
    this.markersAll.forEach(m => {
      if (southWestLong < m.position.lng && northEastLong > m.position.lng &&
        southWestLat < m.position.lat && northEastLat > m.position.lat
      ) {
        markersIn.push(m);
      }
    });

    const applyMarkers = markersIn.length > this.markersLimit ? [] : markersIn;

    // do not overwrite marker array: keep selected markers in this.markers array
    // reason: while panning selected marker will not continue to appear
    applyMarkers.forEach(m => {
      if (!this.markers.find(_ => m.deviceId === _.deviceId)) {
        this.markers.push(m);
      }
    });

    // remove marker
    this.markers
      .filter(m => !applyMarkers.find(_ => m.deviceId === _.deviceId))
      .forEach(rm => {
        const pos = this._indexOfMarker(this.markers, rm.deviceId);
        this.markers.splice(pos, 1);
      });

    this.syncSelectedDevice();
  }

  private adjustStreetName(street: string | undefined): string {
    if (!street) {
      return '';
    }
    return street.replace(/str\.?$/i, 'straße');
  }

  private _indexOfMarker(array, deviceId) {
      for (let x = 0; x < array.length; x++) {
          if (array[x].deviceId === deviceId) { return x; }
      }
      return null;
  }

  private pushToMarkers(device: Device, selected: boolean): void {
    this.markers.push(this.createMarker(device, selected));
  }

  private isNumberAControlCabinet(technicalNumber: string): boolean {
    const patternRegex = /^[A-Z]{5}-[A-Z]-\d{4}$/;
    return patternRegex.test(technicalNumber);
  }

  private isNumberALightningPoint(technicalNumber: string): boolean {
    const customPatternRegex = /^[A-Z]{5}-(SOLAR|PRIVA|\d{5})-\d{5}$/;
    return customPatternRegex.test(technicalNumber);
  }

  private setLightPointMarkerColor(device: Device): string {
    return this.inProgressIncidentStates.includes(device.incidentStatus) ? this.colorHorizontorange : this.colorTiefenblau;
  }

  private createMarker(device: Device, selected: boolean): any {
    const color = this.getMarkerColor(device, selected);
    const icon = this.createIcon(selected, color);
    const infoText1 = this.getInfoText1(device);
    const infoText2 = this.getInfoText2(device);
    const missingDataLabel = this.getMissingDataLabel(device);
    return {
      deviceId: device.id,
      position: {
        lat: device.latitude,
        lng: device.longitude,
      },
      infoText1: infoText2,
      infoText2: infoText1,
      infoText3: missingDataLabel,
      options: { icon },
      selected
    };
  }

  private createIcon(selected: boolean, color: string): any {
    return {
      path: selected ? google.maps.SymbolPath.BACKWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillColor: color,
      fillOpacity: 1,
      strokeColor: color,
      strokeOpacity: 0,
    };
  }

  private getMarkerColor(device: Device, selected: boolean): string {
    if (this.isNumberALightningPoint(device.technicalNo)) {
      return selected ? this.colorSelectedBlue : this.setLightPointMarkerColor(device);
    } else if (this.isNumberAControlCabinet(device.technicalNo)) {
      return selected ? this.colorSelectedBlue : this.colorControlCabinet;
    }

    return 'none';
  }

  private getInfoText1(device: Device): string {
    return `${this.isNumberALightningPoint(device.technicalNo) ? 'LeuchtenNr.:' : 'SchaltstellenNr.:'} ${device.technicalNo}`;
  }

  private getInfoText2(device: Device): string {
    const street = ObjUtils.isNullOrUndefinedOrEmpty(device.street) ? '' : device.street;
    const houseNo = ObjUtils.isNullOrUndefinedOrEmpty(device.houseNo) ? '' : device.houseNo;
    const zipCode = ObjUtils.isNullOrUndefinedOrEmpty(device.zipCode) ? '' : device.zipCode;
    const city = ObjUtils.isNullOrUndefinedOrEmpty(device.city) ? '' : device.city;
    const cityDistrict = ObjUtils.isNullOrUndefinedOrEmpty(device.cityDistrict) ? '' : `/${device.cityDistrict}`;

    return `${street} ${houseNo}, ${zipCode} ${city} ${cityDistrict}`;
  }

  private getMissingDataLabel(device: Device): string {
    return `
      ${ObjUtils.isNullOrUndefinedOrEmpty(device.street) ||
      ObjUtils.isNullOrUndefinedOrEmpty(device.houseNo) ||
      ObjUtils.isNullOrUndefinedOrEmpty(device.zipCode) ||
      ObjUtils.isNullOrUndefinedOrEmpty(device.city) ||
      ObjUtils.isNullOrUndefinedOrEmpty(device.zipCode) ? 'Daten in LuxData unvollständig hinterlegt' : ''}`;
  }

  private removeFromMarkers(device: Device): void {
    const index: number = this.markers.findIndex(elem => elem.deviceId === device.id);
    this.markers.splice(index, 1);
  }

  private close(dialogResult: DialogResult): void {
    this.data.dialogResult = dialogResult;
    this.readSelectedLocationValues();
    this.dialogRef.close(this.data);
  }

  private readSelectedLocationValues(): void {
    if (ObjUtils.isNullOrUndefined(this.selectedDevice)) {
      return;
    }
    this.data.locationData.street = this.selectedDevice.street;
    this.data.locationData.houseNo = this.selectedDevice.houseNo;
    this.data.locationData.zipCode = this.selectedDevice.zipCode;
    this.data.locationData.city = this.selectedDevice.city;
    this.data.locationData.cityDistrict = this.selectedDevice.cityDistrict;
    this.data.locationData.lightPointNo = this.isNumberALightningPoint(this.selectedDevice.technicalNo) ? this.selectedDevice.technicalNo : null;
    this.data.locationData.controlCabinetNo = this.isNumberAControlCabinet(this.selectedDevice.technicalNo) ? this.selectedDevice.technicalNo : this.selectedDevice.controlCabinetNo;
    this.data.locationData.customerScope = this.selectedDevice.customerScope;
    this.data.locationData.netArea = this.selectedDevice.netArea ? this.selectedDevice.netArea : this.data.locationData.netArea;
    this.data.locationData.houseNo = this.selectedDevice.houseNo ? this.selectedDevice.houseNo : this.data.locationData.houseNo;
  }

  private getCorrectRequestMethodName(): string {
    if (this.isLightningPointChecked && this.isControlCabinetChecked) {
      return this.isNumberAControlCabinet(this.reportForm.value.location.allNo) ? 'getControlCabinetDataById' : 'getLightpointDataById';
    }
    return this.isControlCabinetChecked ? 'getControlCabinetDataById' : 'getLightpointDataById';
  }

  private removePLZFromGoogleAddress(address: string, city: string): string {
    const addressParts = address.split(',');
    let fixedAddress = '';
    addressParts.forEach(part => {
      if (part.indexOf(city) > -1) {
        fixedAddress += part.replace(/\d/g, '');
      } else {
        fixedAddress += part;
      }
    });
    return fixedAddress;
  }

  // tslint:disable-next-line:max-line-length
  private getValidPlace(places: google.maps.places.AutocompletePrediction[], city: string, address: any): google.maps.places.AutocompletePrediction {
    if (!city) {
      return places[0];
    }

    const cityTrimmed = city.trim().toLowerCase();
    const street = this.adjustStreetName(address.street?.trim().toLowerCase());
    const houseNo = address.houseNo?.trim().toLowerCase();

    // 1) Look for an exact match (street, houseNo, city)
    const exactPlace = places.find(place => {
      const placeWords = this.parsePlaceToStringList(place);
      return placeWords.includes(street + ' ' + houseNo)
        && placeWords.includes(cityTrimmed);
    });

    if (exactPlace) {
      return exactPlace;
    }

    // 2) Look for an exact match without street number (street, city)
    const noStreetNumber = places.find(place => {
      const placeWords = this.parsePlaceToStringList(place);
      return !!placeWords.find(word => word.includes(street))
        && placeWords.includes(cityTrimmed);
    });

    if (noStreetNumber) {
      return noStreetNumber;
    }

    // 3) Look for a match with exact address but only partial city match (street, houseNo, city substring)
    const exactAddress = places.find(place => {
      const placeWords = this.parsePlaceToStringList(place);
      return placeWords.includes(street + ' ' + houseNo)
        && !!placeWords.find(word => word.includes(cityTrimmed));
    });

    if (exactAddress) {
      return exactAddress;
    }

    // 4) Look for just the street match and partial city match (street, city substring)
    const justStreet = places.find(place => {
      const placeWords = this.parsePlaceToStringList(place);
      return !!placeWords.find(word => word.includes(street))
        && !!placeWords.find(word => word.includes(cityTrimmed));
    });

    if (justStreet) {
      return justStreet;
    }

    // 5) Look for the places where the address that user is looking for shows in any results separated by comma
    const partsResult = places.find(place => {
      const parts = this.parseToPartList(place);
      return !!parts.find(word => word.includes(street))
        && !!parts.find(word => word.includes(cityTrimmed))
        && !!parts.find(word => word.includes(houseNo));
    });

    if (partsResult) {
      return partsResult;
    }

    // If none of the above criteria matched, return the first place
    return places[0];
  }

  private parsePlaceToStringList(place: google.maps.places.AutocompletePrediction): string[] {
    return place.description.toLowerCase()
      .split(',')
      .map(word => word.trim().toLowerCase());
  }

  private parseToPartList(place: google.maps.places.AutocompletePrediction): string[] {
    return place.description.toLowerCase().split(',');
  }
}
