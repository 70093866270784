/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User Mapping Role type
 */
export type UserMappingRole = 'CONSTRUCTION_COORDINATION' | 'OPERATIONS_SERVICE' | 'EXTERNAL_SERVICE_PROVIDER' | 'TECHNICAL_SALES' | 'TECHNICAL_INSIDE_SALES' | 'INTERNAL_INSTALLER' | 'FOREMAN' | 'PROJECT_PLANNING';

export const UserMappingRole = {
    ConstructionCoordination: 'CONSTRUCTION_COORDINATION' as UserMappingRole,
    OperationsService: 'OPERATIONS_SERVICE' as UserMappingRole,
    ExternalServiceProvider: 'EXTERNAL_SERVICE_PROVIDER' as UserMappingRole,
    TechnicalSales: 'TECHNICAL_SALES' as UserMappingRole,
    TechnicalInsideSales: 'TECHNICAL_INSIDE_SALES' as UserMappingRole,
    InternalInstaller: 'INTERNAL_INSTALLER' as UserMappingRole,
    Foreman: 'FOREMAN' as UserMappingRole,
    ProjectPlanning: 'PROJECT_PLANNING' as UserMappingRole
};

