import { ActionsModalOperationType } from '../../models/enums/company/actions-modal-operation-type';
import { CompanyTO, UserMappingRole } from '../../../api/generated/v2/incident';

export class UserMappingDataModalImpl {
  operation: ActionsModalOperationType;
  uuid?: string;
  company?: CompanyTO;
  userName?: string;
  role?: UserMappingRole;
  type?: string;
  email?: string;
}
